import React from "react";
import { connect } from 'react-redux';

import Loader from "../../components/Loader/Loader";
import SignupForm from "../../components/UserSignup/SignupForm";
import SetPasswordForm from "../../components/UserSignup/SetPasswordForm";

import { alertActions } from '../../actions';
import { apiService } from "../../services/api_service";

import UserHeader from "../../components/UserHeader/UserHeader";
import { getThemeColor } from "../../constants/theme-color";




class SetPassword extends React.Component {
  constructor(props) {
    super(props)
    // this.props.logout(this.props.history);

    this.state = {
      user_id: "",
      email: "",
      code: "",
      newpassword: "",
      confirmpassword: "",
      phone: "",
      gender:"",
      submitted: false,
      hide: true,
      loading: false,
      emailError: false,
      passwordError: false,
      passwordFormat: false,
      hide1: true,
      confirmError: false,
      pwdSuccess: false,
      countries: [],
      partner_id: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.passwordFormSubmit = this.passwordFormSubmit.bind(this);
    this.signupFormSubmit = this.signupFormSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true })
    // set default color
    // document.getElementById("bodyID").style.setProperty('--themecolor', '#6e2b8b');
    // document.getElementById("bodyID").style.setProperty('--themefontcolor', '#6e2b8b');
    // document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#6e2b8b');
    // document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#6e2b8b');
    // document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#6e2b8b');
    // document.getElementById("bodyID").style.setProperty('--filterSvg', '');
    let token = this.props.match.params.token;
    console.log("User token", token)

    let resp = await apiService.verifyResetLink(token);
    console.log("responedata", resp.data)
    if (resp.success) {
      let countries = await apiService.getCountries();
      // console.log("countries", countries);
      this.setState({
        user_id: Buffer.from(String(resp.data.id)).toString('base64'),
        email: resp.data.email,
        phone: resp.data.phone,
        gender: resp.data.gender,
        countries: countries,
        loading: false,
        partner_id: resp.data.partner_id,
        token
      });
    } else {
      this.props.history.push(`/linkexpired?partner_id=${this.state.partner_id}`)
    }
    
    
  }

  handleChange(e) {
    const { name, value } = e.target;
    // console.log("name", name, "value", value);
    this.setState({ [name]: value });
  }

  async passwordFormSubmit(formData) {
    this.setState({ submitted: true });
    const {newpassword, confirmpassword } = formData;
    if (this.state.token && newpassword && confirmpassword) {
      let password = Buffer.from(newpassword).toString('base64');
      let resp = await apiService.userSetPassword(this.state.token, password);
      if (resp.success) {
        this.setState({loading : true})
        setTimeout(() => {
          this.props.success("Successfully set the password")
          this.setState({ pwdSuccess: true, loading : false })

          setTimeout(() => {
            this.props.clearAlerts();
          },4000)
        }, 4000)

       
      } else {
        this.props.error(resp.message);
        setTimeout(() => {
          // this.setState({pwdSuccess: true})
          this.props.clearAlerts();
        }, 4000)
      }
    }

  }
  async signupFormSubmit(formData) {
    this.setState({ submitted: true });
    const { phone, user_id } = this.state;
    const { heightCm, heightIn, heightFt, heightUnit, weightUnit, weightKg, weightGm, weightLbs, gender, country } = formData;
    console.log("weightGm", weightGm)
    let data = {
        user_id: user_id,
        height: heightUnit == "cm" ? heightCm : parseInt(heightFt) + (0.0833 * parseInt(heightIn)),
        weight: weightUnit == "lbs" ? weightLbs : weightKg + "." + weightGm,
        height_unit: heightUnit,
        weight_unit: weightUnit,
        gender,
        country,
        phone
    }

    console.log("data for api", data);
    let resp = await apiService.completeUserSignup(data);
    if (resp.success) {
        this.props.success("Signup completed successfully");
        setTimeout(() => {
            this.props.clearAlerts();
            this.props.history.push(`/signupsuccess?partner_id=${this.state.partner_id}`);
        }, 3000)
    } else {
        this.props.error(resp.message)
    }
}

  handleClose = () => {
    this.props.clearAlerts();
  }

  render() {
   
   
    const { alert} = this.props;
    const { pwdSuccess, loading, email, countries, phone,token,submitted,gender,partner_id } = this.state;
    const themeColor = getThemeColor(partner_id);
    const style = { marginTop: '0px' } ;

    return (
      <>
       <UserHeader partner_id = {partner_id}></UserHeader>
        <div className="formPart-reset signupForm"  >
          {
            alert.message &&
            <div className={`alert ${alert.type}`} style={{ marginBottom: 0 }}>
              {alert.message}
              <button className="close" onClick={this.handleClose} >x</button>
            </div>
          }
         {loading ? <Loader loading={loading} />
         :  pwdSuccess ?
         <>
           <SignupForm
             themeColor = {themeColor}
             handleSubmit={this.signupFormSubmit}
             handleChange={this.handleChange}
             countries={countries}
             phone={phone}
             gender={gender}
           ></SignupForm>
         </>
         :
         <>
           <SetPasswordForm 
             themeColor = {themeColor}
             token={token}
             email={email}
             handleSubmit={this.passwordFormSubmit}
             submitted={submitted}
           ></SetPasswordForm>
         </> 
        }
        </div>
      </>
    );
  }
};

function mapState(state) {
  const { alert } = state;
  return {alert};
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  success: alertActions.success,
  error: alertActions.error
}
export default connect(mapState, actionCreators)(SetPassword);
