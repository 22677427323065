import { React } from "react";
import { withTranslation } from "react-i18next";

import PieChart from "../../components/Graphs/pieChart/pieChart";
import { getColorCode } from "../../helpers/getColorCode";


const OverviewDetails = ({ name_one, name_two, overview, tabs, compare_overview, compare_tabs, categorySelector, t }) => {
    const combinedGroupedOverview = compare_overview ? overview.map((item, index) => {
        const compareItem = compare_overview.length > index ? compare_overview[index] : null;
    
        var title = item != null ? item.title : (compareItem != null ? compareItem.title : "");
        var itemScore = item != null ? item.score : 0;
        let compareScore = compareItem != null ? compareRsids(item, compareItem) : 0;

        return {
            ...item != null ? item : compareItem,
            // score: ((itemScore + compareScore > 2) ? 2 : (itemScore + compareScore)),
            score: compareScore || 0,
            title: `${title}`
        };
    }) : null;

    function compareRsids(outcome1, outcome2) {
        if (outcome2 == null)
            return outcome1.score;

        let compareScore = outcome1.score + outcome2.score
        if (compareScore < 2)
            return compareScore;

        let array1 = outcome1.alleles, array2 = outcome2.alleles;
        // console.log("array1", array1, "array2", array2)

        const rsidSet = new Set(array1.map(item => item.rsid));

        for (const item of array2) {
            if (rsidSet.has(item.rsid) && array1.find(a => a.rsid === item.rsid).value === 1 && item.value === 1) {
                return 2;
            }
        }
        return 1;
    }
    return (
        <>
            <div>
                {
                    compare_overview != null ?
                        (
                            <div style={{ height: "50px", display: "flex", flexDirection: "row", textAlign: "center", marginTop: "10px" }}>
                                <div style={{ flex: 1 }}>
                                    <h5>{name_one}</h5>
                                </div>
                                <div style={{ flex: 1, marginLeft: "20px" }}>
                                    <h5>{name_two}</h5>
                                </div>
                                <div style={{ flex: 1, marginLeft: "20px" }}>
                                    <h5>{t('title.combined')}</h5>
                                </div>
                            </div>
                        )
                        :
                        <div></div>
                }
                <div className="reportDetail-wrp">
                    <div className="row">
                        <div className={compare_overview != null ? "col-4" : "col-12"}>
                            <div className="row" style={{ justifyContent: compare_overview != null ? "center" : "none" }}>
                                {overview && overview.map((item, index) => (
                                    <div key={index} className={compare_overview == null ? "col-12 col-md-4 col-lg-3 col-xl-2 mb-5" : "col-lg-8  col-sm-12 mb-3"} onClick={() => categorySelector(tabs[index])}>
                                        <h5 className="fontSizeReport" style={{ height: 40, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingBottom: "50px", cursor: "pointer" }}>{tabs[index].title}</h5>
                                        {compare_overview === null ? <PieChart style={"congenital_report"} className="pie-chart-overview" summary={item} num={item.score} graphColor={getColorCode(item.score)} /> :
                                            <PieChart className="pie-chart-overview" summary={item} num={item.score} graphColor={getColorCode(item.score)} />}
                                        <p className="fontSizeReport" style={{ fontWeight: 500, marginTop: "5%", textAlign: "center", paddingTop: "15px", cursor: "pointer", color: getColorCode(item.score) }}>{item.title}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {compare_overview != null && (
                            <div className="col-4">
                                <div className="row" style={{ justifyContent: "center" }}>
                                    {compare_overview.map((item, index) => (
                                        <div key={index} className="col-lg-8 col-sm-12 mb-3">
                                            <h5 className="fontSizeReport" style={{ height: 40, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingBottom: "50px" }}>{tabs[index].title}</h5>
                                            <PieChart className="pie-chart-overview" summary={item} num={item.score} graphColor={getColorCode(item.score)} />
                                            <p className="fontSizeReport" style={{ fontWeight: 500, marginTop: "5%", textAlign: "center", paddingTop: "15px", cursor: "pointer", color: getColorCode(item.score) }}>{item.title}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {combinedGroupedOverview != null && (
                            <div className="col-4">
                                <div className="row" style={{ justifyContent: "center" }}>
                                    {combinedGroupedOverview.map((item, index) => (
                                        <div key={index} className="col-lg-8 col-sm-12 mb-3">
                                            <h5 className="fontSizeReport" style={{ height: 40, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingBottom: "50px" }}>{tabs[index].title}</h5>
                                            <PieChart className="pie-chart-overview" summary={item} num={item.score} graphColor={getColorCode(item.score)} />
                                            <p className="fontSizeReport" style={{ fontWeight: 500, marginTop: "5%", textAlign: "center", paddingTop: "15px", cursor: "pointer", color: getColorCode(item.score) }}>{`Combined Score: ${item.score}`}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );


}

export default withTranslation()(OverviewDetails);
