import React, { useState } from "react";
import { BsTriangle, BsTriangleFill } from "react-icons/bs";
import { withTranslation, useTranslation } from "react-i18next";

<BsTriangle />;
function Indicator({
  sort,
  fieldName = "recent",
  id,
  ascClassName = "",
  descClassName = "",
}) {
  return (
    <div>
      <BsTriangleFill
        onClick={(e) => sort(e, fieldName, "asc", id)}
        className={`sort-btn asc ${ascClassName}`}
      />

      <BsTriangleFill
        className={`sort-btn desc ${descClassName}`}
        onClick={(e) => sort(e, fieldName, "desc", id)}
      />
    </div>
  );
}

function CommonTableHead({
  org,
  setResult,
  disableSorting = false,
  disableIndicator = false,
}) {
  const { t } = useTranslation();
  const [thConfig, setThConfig] = useState({
    indicator: {
      sortType: "desc",
      className: "selectedSort",
    },
    recent: {
      sortType: "",
      className: "",
    },
    previous: {
      sortType: "",
      className: "",
    },
    average: {
      sortType: "",
      className: "",
    },
  });
  function sort(e, field, sortType = "asc", id = "") {
    e.preventDefault();
    let sortedData = [...org]; // Create a new array

    sortedData.sort((a, b) =>
      sortType === "asc" ? a[field] - b[field] : b[field] - a[field]
    );

    setResult(sortedData);
    updateThConfig(id, sortType);
  }

  function updateThConfig(id = "indicator", sortType = "desc") {
    let resetOtherSortOption = {};

    Object.keys(thConfig)
      .filter((key) => key !== id)
      .forEach((key) => {
        resetOtherSortOption[key] = {
          sortType: "",
          className: "",
        };
      });

    setThConfig({
      ...resetOtherSortOption,
      [id]: {
        sortType,
        className: "selectedSort",
      },
    });
  }
  return (
    <>
      {!disableIndicator && (
        <th className="withUpwardDownardArrow">
          Indicator{" "}
          {!disableSorting && (
            <Indicator
              {...{
                sort,
                fieldName: "recent",
                id: "indicator",
                ascClassName:
                  thConfig.indicator.sortType === "" ||
                    thConfig.indicator.sortType === "desc"
                    ? ""
                    : "selectedSort",

                descClassName:
                  thConfig.indicator.sortType === "" ||
                    thConfig.indicator.sortType === "asc"
                    ? ""
                    : "selectedSort",
              }}
            />
          )}
        </th>
      )}
      <th className="txt-center withUpwardDownardArrow">
        {t('cognitive_table.most_recent_score')}{" "}
        {!disableSorting && (
          <Indicator
            {...{
              sort,
              fieldName: "recent",
              id: "recent",
              ascClassName:
                thConfig.recent.sortType === "" ||
                  thConfig.recent.sortType === "desc"
                  ? ""
                  : "selectedSort",

              descClassName:
                thConfig.recent.sortType === "" ||
                  thConfig.recent.sortType === "asc"
                  ? ""
                  : "selectedSort",
            }}
          />
        )}
      </th>
      <th className="txt-center withUpwardDownardArrow">
        {t('cognitive_table.previous_score')}
        {!disableSorting && (
          <Indicator
            {...{
              sort,
              fieldName: "previous",
              id: "previous",
              ascClassName:
                thConfig.previous.sortType === "" ||
                  thConfig.previous.sortType === "desc"
                  ? ""
                  : "selectedSort",

              descClassName:
                thConfig.previous.sortType === "" ||
                  thConfig.previous.sortType === "asc"
                  ? ""
                  : "selectedSort",
            }}
          />
        )}
      </th>
      <th className="txt-center withUpwardDownardArrow">
        {t('cognitive_table.average')}{" "}
        {!disableSorting && (
          <Indicator
            {...{
              sort,
              fieldName: "average",
              id: "average",
              ascClassName:
                thConfig.average.sortType === "" ||
                  thConfig.average.sortType === "desc"
                  ? ""
                  : "selectedSort",

              descClassName:
                thConfig.average.sortType === "" ||
                  thConfig.average.sortType === "asc"
                  ? ""
                  : "selectedSort",
            }}
          />
        )}
      </th>
    </>
  );
}

export default withTranslation()(CommonTableHead);
