import React from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { apiService } from "../../services/api_service";
import { alertActions } from "../../actions";
import { lifestyleTabs } from "../../constants/general-constants";

import PageHead from "../../components/PageHead/PageHead";
import TabItems from "../../components/TabItems/TabItems_old";
import OverviewDetails from "./OverviewDetails";
import OverviewListing from "./OverviewListing";

class LifeStyle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: lifestyleTabs(),
      view: lifestyleTabs()[0],
      loader: true,
      practitioner_id: "",
      email: "",
      access: {},
      lifestyleData: {},
      users_list: [],
      message: this.props.t('messages.select_patients'),
      show: false,
      selected_user: "",
      start: new Date(),
      end: null,
      type: "month",
      clearUser: false,
      _id: "",
    };
  }
  // async componentDidMount(){
  //     this.setState({ loader: true })
  //     try {
  //         let user_details = await apiService.AccessUsersList(this.state.practitioner_id)
  //         // let listData=[]
  //         // await user_details.map(users =>{
  //         //     listData.push({
  //         //         label:users.first_name + users.last_name,
  //         //         value:users._id,
  //         //         kit_id:user.kit_id
  //         //     })

  //         // })
  //         this.setState({
  //             users_list:user_details,
  //             loader:false,
  //         })
  //     } catch (error) {
  //         console.log("error",error)
  //     }

  // }

  async componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        this.setState({ loader: true });
        const practitioner_id = btoa(nextProps.user._id);
        //const users_list = await apiService.AccessUsersList(practitioner_id);
        this.setState({
          //users_list,
          loader: false,
          practitioner_id,
          practitioner_details: nextProps.user,
          ...nextProps.user,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async getLifeStyleData(user_id, con, type) {
    if (!user_id || !this.props.user?._id) return;
    this.setState({ loader: true, selected_user: user_id });
    if (user_id == "select") {
      this.setState({
        loader: false,
        message: "Please select patients from the above dropdown",
        start: con ? new Date(con.start_date) : new Date(),
        end: con ? new Date(con.last_date) : null,
        type: type,
      });
      return;
    }
    this.setState({
      start: con ? new Date(con.start_date) : new Date(),
      end: con ? new Date(con.last_date) : null,
      type: type,
    });
    let userId = Buffer.from(String(user_id)).toString("base64");

    let condition = {
      user_id: userId,
    };

    if (typeof con !== "string") {
      condition = {
        ...condition,
        ...con,
      };
    } else {
      var date = new Date();
      var firstDay = new Date(date.getFullYear() - 1, date.getMonth(), 1);
      condition = {
        ...condition,
        month: moment().month() + 1,
        year: moment().year(),
        type: "year",
        start_date: moment(firstDay).format("YYYY-MM-DD"),
        last_date: moment().format("YYYY-MM-DD"),
      };
    }
    let lifestyleData = await apiService.lifeStyleData(condition);
    if (!lifestyleData.success && lifestyleData.message == "user not found") {
      this.setState({ clearUser: true, loader: false });
      return;
    } else {
      this.setState({ clearUser: false });
      lifestyleData = lifestyleData.data;
    }
    if (lifestyleData && lifestyleData.length > 0) {
      this.setState({ loader: false, lifestyleData: lifestyleData[0] });
      return;
    }
    this.setState({
      loader: false,
      message: "No data found for this date",
      lifestyleData: {},
    });
  }

  async filterdData(con, type) {
    await this.getLifeStyleData(this.state.selected_user, con, type);
  }

  async ChangeTabs(name) {
    this.setState({ view: name });
    this.ViewDetailedpage(name);
  }

  async ViewDetailedpage(name) {
    if (name.value === "overview") {
      await this.getLifeStyleData(this.state.selected_user);
      this.setState({ show: false });
      return;
    }
    this.setState({ show: true });
  }

  detailsOfSelectedTab(name) {
    console.log(name, "detailsOfSelectedTab");
    const { t } = this.props;
    let lifestyleData = this.state.lifestyleData;

    switch (name.value) {
      case "steps":
        return {
          Title: t('lifestyle_data.steps'),
          list: lifestyleData.step,
          graph_type: "bar",
          // avg: lifestyleData.step_counts + " steps",
          avg: t('lifestyle_measurements.steps', { num: lifestyleData.step_counts }),
          // value1: lifestyleData.step_calorie_burned + " kcal",
          value1: t('lifestyle_measurements.kcal', { num: lifestyleData.step_calorie_burned }),
          // value2: lifestyleData.step_distance + " km",
          value2: t('lifestyle_measurements.km', { num: lifestyleData.step_distance }),
          main_img: "/images/lifestyle/steps.svg",
          list_img1: "/images/lifestyle/stepindicator1.svg",
          list_img2: "/images/lifestyle/stepindicator2.svg",
          xAxis: t('lifestyle_graph.steps'),
          icons: "/images/lifestyle/stepicon.svg",
          date: lifestyleData.date,
          last3Date: lifestyleData.last3data[0].date,
          last3list: lifestyleData.last3data[0].step,
          msg: lifestyleData.last3data[0].step_message,
        };
      case "sleep":
        return {
          Title: t('lifestyle_data.sleep'),
          list: lifestyleData.sleep,
          graph_type: "bar",
          // avg: lifestyleData.total_sleep + " Min",
          avg:  t('lifestyle_measurements.min', { num: lifestyleData.total_sleep }),
          main_img: "images/lifestyle/sleep.svg",
          list_img1: "images/lifestyle/sleepindicator1.svg",
          list_img2: "images/lifestyle/sleepindicator2.svg",
          xAxis: t('lifestyle_graph.sleep'),
          icons: "/images/lifestyle/sleepicon.svg",
          date: lifestyleData.date,
          last3Date: lifestyleData.last3data[0].date,
          last3list: lifestyleData.last3data[0].sleep,
          msg: lifestyleData.last3data[0].sleep_message,
        };
      case "active_time":
        return {
          Title: t('lifestyle_data.active_time'),
          list: lifestyleData.running,
          list2: lifestyleData.walking_times,
          list3: lifestyleData.walking_times,
          graph_type: "line",
          avg:  t('lifestyle_measurements.min', { num: lifestyleData.active }),
          value1: t('lifestyle_measurements.min', { num: lifestyleData.walking_time }),
          value2: t('lifestyle_measurements.min', { num: lifestyleData.running_time }),
          value3: t('lifestyle_measurements.min', { num: lifestyleData.weights_time }),
          cal: t('lifestyle_measurements.kcal', { num: lifestyleData.calorie_burned }),
          distance: t('lifestyle_measurements.km', { num: lifestyleData.distance }),
          main_img: "images/lifestyle/activetime.svg",
          list_img1: "images/lifestyle/acttivetimeindicator1.svg",
          list_img2: "images/lifestyle/acttivetimeindicator2.svg",
          list_img3: "images/lifestyle/acttivetimeindicator3.svg",
          xAxis: t('lifestyle_graph.active_time'),
        };
      case "heart_rate":
        return {
          Title: t('lifestyle_data.heart_rate'),
          list: lifestyleData.avg_heart_rate,
          list2: lifestyleData.min_heart_rate,
          list3: lifestyleData.max_heart_rate,
          graph_type: "line",
          avg: t('lifestyle_measurements.bpm', { num: lifestyleData.heart_rate_avg }),
          min: t('lifestyle_measurements.bpm', { num: lifestyleData.heart_rate_min }),
          max: t('lifestyle_measurements.bpm', { num: lifestyleData.heart_rate_max }),
          main_img: "images/lifestyle/heartrate.svg",
          list_img1: "images/lifestyle/sleepindicator1.svg",
          list_img2: "images/lifestyle/sleepindicator2.svg",
          xAxis: t('lifestyle_graph.heart_rate'),
        };
      case "water_intake":
        return {
          Title: t('lifestyle_data.water_intake'),
          list: lifestyleData.water_intakes,
          graph_type: "bar",
          avg: t('lifestyle_measurements.ml', { num: lifestyleData.water_intake }),
          main_img: "images/lifestyle/waterintake.svg",
          list_img1: "images/lifestyle/waterindicator1.svg",
          list_img2: "images/lifestyle/waterindicator2.svg",
          xAxis: t('lifestyle_graph.water_intake'),
          icons: "/images/lifestyle/waterintakeicon.svg",
          date: lifestyleData.date,
          last3Date: lifestyleData.last3data[0].date,
          last3list: lifestyleData.last3data[0].water_intakes,
          msg: lifestyleData.last3data[0].water_intakes_message,
        };
      case "calorie_intake":
        return {
          Title: t('lifestyle_data.calorie_intake'),
          list: lifestyleData.calorie_intakes,
          graph_type: "bar",
          avg: t('lifestyle_measurements.kcal', { num: lifestyleData.calorie_intake }),
          main_img: "images/lifestyle/calorieintake.svg",
          list_img1: "images/lifestyle/caloriesintake1.svg",
          list_img2: "images/lifestyle/caloriesintake2.svg",
          xAxis: t('lifestyle_graph.calorie_intake'),
          icons: "/images/lifestyle/calorieintakeicon.svg",
          date: lifestyleData.date,
          last3Date: lifestyleData.last3data[0].date,
          last3list: lifestyleData.last3data[0].calorie_intakes,
          msg: lifestyleData.last3data[0].calorie_intakes_message,
        };

      default:
        return { list: this.state.lifestyleData.step };
    }
  }

  render() {
    const { t } = this.props;
    const { tab, view, users_list, lifestyleData, loader, message,
      show, start, end, type, clearUser, } = this.state;

    return (
      <>
        <div className="lifestyle-wrp">
          <PageHead
            title={t('title.lifestyle_data')}
            UserList={users_list}
            clearUser={clearUser}
            DetailsGetAPI={this.getLifeStyleData.bind(this)}
            onUserClear={() => {
              this.setState({ lifestyleData: [] })
            }}
          ></PageHead>

          <TabItems
            tabs={tab}
            view={view}
            ChangeTabs={this.ChangeTabs.bind(this)}
          ></TabItems>
          <div className="selectionBar">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-auto">
                <h3>{t('title.lifestyle_data')} </h3>
              </div>
            </div>
          </div>

          {loader ? (
            <Spinner
              className="spinnerClass"
              animation="border"
              variant="info"
              style={{
                marginLeft: "50%",
                padding: "20px",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            />
          ) : lifestyleData.date ? (
            show ? (
              <OverviewDetails
                view={view}
                lifestyleDetails={lifestyleData}
                details={this.detailsOfSelectedTab(view)}
                DetailsGetAPI={this.filterdData.bind(this)}
                start_date={start}
                end_date={end}
                type={type}
                clearUser={clearUser}
              ></OverviewDetails>
            ) : (
              <OverviewListing
                lifestyleDetails={lifestyleData}
                ChangeTabs={this.ChangeTabs.bind(this)}
                ViewDetailedpage={this.ViewDetailedpage.bind(this)}
                clearUser={clearUser}
              ></OverviewListing>
            )
          ) : (
            <h5 style={{ textAlign: "center", padding: "20px" }}>{message}</h5>
          )}
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
};

// export default connect(mapState, actionCreators)(LifeStyle);
export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(LifeStyle);