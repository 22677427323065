import React from "react";

import { PrivateRoute } from '../Route/PrivateRoute'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'
import { connect } from "react-redux";
import { alertActions, userActions } from "../../actions"
import { history } from "../../helpers";
import { apiService } from "../../services/api_service";
import { withRouter } from 'react-router-dom';
import { userService } from "../../services";
import Alert from "../Alert/Alert";

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hello: "hello",
      hide: true,
      partner_id: this.props.user?.partner_id || null,
    };
    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
    this.handleClose = this.handleClose.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps?.user?._id) {
      let partner_id = nextProps?.user?.partner_id;
      if (partner_id == 25 || partner_id == 31 || partner_id == 33) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#6e2b8b");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#6e2b8b");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#84a864");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#ffe6ff");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#c38adb");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "22px 0px");
        document
          .getElementById("bodyID")
          .style.setProperty(
            "--filterSvg",
            "invert(10%) sepia(108%) saturate(6855%) hue-rotate(260deg) brightness(93%) contrast(70%)"
          );
      } else if (partner_id == 26) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#398cb2");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#398cb2");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#84a864");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#ebf4f7");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#398cb2");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "22px 0px");
        document
          .getElementById("bodyID")
          .style.setProperty(
            "--filterSvg",
            "invert(52%) sepia(71%) saturate(414%) hue-rotate(154deg) brightness(82%) contrast(87%)"
          );
      } else if (partner_id == 28) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#00CBB2");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#00CBB2");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#a6ede4");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#ebf4f7");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#00e6cb");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "22px 0px");
        document.getElementById("bodyID").style.setProperty("--filterSvg", "");
      } else if (partner_id == 7) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#000000");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#000000");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#a6a6a6");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#e6e6e6");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#818181");
        document
          .getElementById("bodyID")
          .style.setProperty("--navtext", "#ffffff");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "9px 0px");
        document
          .getElementById("bodyID")
          .style.setProperty(
            "--filterSvg",
            " invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)"
          );
      } else if (partner_id == 13) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#17629e");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#17629e");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#63ace9");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#d2e7f9");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#2089df");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "15px 0px");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontsize", "13px");
        document
          .getElementById("bodyID")
          .style.setProperty(
            "--filterSvg",
            "invert(21%) sepia(82%) saturate(549%) hue-rotate(147deg) brightness(58%) contrast(80%)"
          );
      } else if (partner_id === 45) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#080B37");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#080B37");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#e9eafc");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#e9eafc");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#3642e2");
        document
          .getElementById("bodyID")
          .style.setProperty("--navtext", "#ffffff");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "12px 0px");
      } else if (partner_id === 46) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#2c2c2c");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#2c2c2c");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#b3b3b3");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#f2f2f2");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#e6e6e6");
        document
          .getElementById("bodyID")
          .style.setProperty("--navtext", "#ffffff");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "22px 0px");
      }
      else if (partner_id === 48) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#65C0B6");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#65C0B6");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#c9e9e5");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#edf8f6");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#b7e1dc");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "9px 0px");
      }
      else if (partner_id === 11) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#76D3CA");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#76D3CA");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#c5ece8");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#ecf9f7");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#d8f3f0");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "22px 0px");
      }
      else if (partner_id === 15) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#243144");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#243144");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#bcc9dc");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#eef1f6");
        document
          .getElementById("bodyID")
          .style.setProperty("--navtext", "#ffffff");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#cdd6e5");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "15px 0px");
      }
      else if (partner_id === 50) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#05aaff");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#05aaff");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#b3e6ff");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#cceeff");
        document
          .getElementById("bodyID")
          .style.setProperty("--navtext", "#ffffff");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#cdd6e5");

      }
      else if (partner_id === 51) {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#b34944");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#b34944");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#f8eded");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#f8eded");
        document
          .getElementById("bodyID")
          .style.setProperty("--navtext", "#ffffff");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#dca5a3");

      }
      else if (partner_id === 52) {
        document.getElementById("bodyID").style.setProperty("--themecolor", "#6037d9");
        document.getElementById("bodyID").style.setProperty("--themefontcolor", "#6037d9");
        document.getElementById("bodyID").style.setProperty("--liteThemecolor", "#b7a5ee");
        document.getElementById("bodyID").style.setProperty("--liteBackgroundColor", "#d7cdf6");
        document.getElementById("bodyID").style.setProperty("--liteButtonBackground", "#aa81ff");
        document.getElementById("bodyID").style.setProperty("--navtext", "#ffffff");
        document.getElementById("bodyID").style.setProperty("--headpadding", "22px 0px");
      }
      else {
        document
          .getElementById("bodyID")
          .style.setProperty("--themecolor", "#09a79e");
        document
          .getElementById("bodyID")
          .style.setProperty("--themefontcolor", "#09a79e");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteThemecolor", "#09a79e69");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteBackgroundColor", "#09a79e17");
        document
          .getElementById("bodyID")
          .style.setProperty("--liteButtonBackground", "#0ac2b9");
        document
          .getElementById("bodyID")
          .style.setProperty("--headpadding", "22px 0px");
        document.getElementById("bodyID").style.setProperty("--filterSvg", "");
      }
    }
  }

  componentDidUpdate() {
    if (this.props.alert.message) {
      setTimeout(() => {
        this.props.clearAlerts();
      }, 5000);
    }
  }

  handleClose = () => {
    this.props.clearAlerts();
  };

  unauthorizePage = () => {
    window.location.href = "/404";
    this.props.errorAlerts("You are trying to access an unauthorized page.");
    history.push("/404");
    userService.logout("404")
    //this.props.logout();
    //window.location.reload();
  };

  unhide = () => {
    this.setState({ hide: !this.state.hide });
  };

  render() {
    return (
      <>
        <div className="header-fixed">
          <Alert />
          <Header hide={this.state.hide} unhide={() => this.unhide()}></Header>
        </div>
        <SideMenu
          className={this.state.hide ? "sideMenu" : "sideMenu-hide"}
          path={window.location.pathname}
          top={this.props.alert.message ? 130 : 85}
          unauthorizePage={() => this.unauthorizePage()}
        ></SideMenu>
        <section
          className={`mainContent department-wrp ${this.props.alert.message ? "section-alert" : "section"
            }`}
        >
          <div className="mainContent-in">{this.props.children}</div>
        </section>
      </>
    );
  }
}

function mapState(state) {
  const { alert, authentication, current } = state;
  const { loggingIn, user } = authentication;
  return { loggingIn, current, user, alert };
}

const actionCreators = {
  errorAlerts: alertActions.error,
  clearAlerts: alertActions.clear,
  login: userActions.login,
  logout: userActions.logout
};
DashboardLayout = connect(mapState, actionCreators)(DashboardLayout)


const DashboardLayoutRoute = ({ component: Component, alert, handleClose, ...rest }) => {

  return (
    <PrivateRoute {...rest} render={matchProps => (
      <DashboardLayout>
        <Component {...matchProps} />
      </DashboardLayout>
    )} />
  )
};

export default withRouter(DashboardLayoutRoute);
