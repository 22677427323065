import React from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";

class SearchBox extends React.Component {

    constructor(props) {
        super(props)
    }

    async SearchItem(event) {
        let item = event.target.value
        console.log("item", item)
        this.props.UserListBYSearch(event.target.value)
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="searchBox-wrp">

                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-0">

                            <Form.Control placeholder={t('placeholder.search')} onChange={this.SearchItem.bind(this)} />
                            <Form.Control.Feedback>
                                <BiSearch
                                    size={20}
                                    style={{ margin_top: '10px' }}
                                />

                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </div>
            </>
        )
    }
}


export default withTranslation()(SearchBox)
