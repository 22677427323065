import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Modal, Spinner, Dropdown, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";

import DoughnutCard from "../../components/Graphs/DoughnutCard/DoughnutCard";
import OverviewCard from "../../components/OverviewCard/OverviewCard";
import BarGraph from "../../components/Graphs/BIBarGraph";
import { apiService } from '../../services/api_service'
import { alertActions } from "../../actions";

class Tracking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      practitioner_id: "",
      step: 0,
      sleep: 0,
      heart_rate: 0,
      tracking: {},
      loader: false,
      avg_question: 0,
      show: false,
      title: "",
      date: new Date(),
      date1: new Date(),
      bioAge: 0,
      data: [],
      label: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  }
  async componentDidMount() {
    try {
      if (this.props.user?._id) {
        const practitioner_id = btoa(this.props.user?._id);

        this.setState({ loader: true, practitioner_id, ...this.props.user })

        let users = await apiService.TrackingCount(practitioner_id)
        let lifestyleUser = await apiService.lifestyleCount(practitioner_id)
        let questionScore = await apiService.questionScore(practitioner_id)
        let bioAge = await apiService.bioAge(practitioner_id)

        let count = 0, avg_question = 0
        if (questionScore.length > 0) {
          console.log("questionscore 123", questionScore[0])
          let question = questionScore[0].question
          question.map((items) => items.length > 0 ? count++ : null)
          avg_question = Math.round((count / questionScore[0].question.length) * 100)
        }
        let withTracking = 0, withoutTracking = 0
        if (users) {
          withTracking = Math.floor(users.withTracking)
          withoutTracking = Math.floor(users.withoutTracking)
        }

        this.setState({
          step: lifestyleUser.length > 0 ? (lifestyleUser[0].step_counts || 0) : 0,
          sleep: lifestyleUser.length > 0 ? (lifestyleUser[0].total_sleeps || 0) : 0,
          heart_rate: lifestyleUser.length > 0 ? (lifestyleUser[0].avg || 0) : 0,
          bioAge: bioAge ? bioAge.age : 52,
          tracking: {
            withTracking,
            withoutTracking
          },
          loader: false,
          avg_question,
        })
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async handleChangeStart(date) {
    this.setState({ date });
  }

  async handleChangeEnd(date1) {
    this.setState({ date1 });
  }

  async handleOpen(type) {
    let title = "", date = new Date()
    switch (type) {
      case "steps":
        title = "Average of Daily Steps"
        date = this.state.date
        break;
      case "questions":
        title = "Average Questionnaire Score"
        date = this.state.date
        break;
      case "bioage":
        title = "Average Biological Age"
        date = this.state.date
        break;
      default:
        title = "Average of Daily Steps"
        date = this.state.date
        break;
    }
    this.setState({ show: true, title, date, data: [], label: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] })
  }

  handleClose() {
    this.setState({ show: false, title: "", data: [], label: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] })
  }

  async handleSubmit(name) {
    if (!this.state.practitioner_id) return
    if (name.toLowerCase().includes("steps")) {
      let data = [], label = []
      console.log("name1", name, this.state.date, this.state.date1)
      console.log("difference", this.state.date1 - this.state.date)
      let result = await apiService.StepCount(this.state.practitioner_id, "week", this.state.date.toISOString().split('T')[0], this.state.date1.toISOString().split('T')[0])
      console.log("result", result)
      result.map((items, index) => {
        console.log("items", items)
        if (items.step_counts > 0) {
          data.push(items.step_counts)
          label.push(items._id)
        }
      })
      this.setState({ data, label })
    } else if (name.toLowerCase().includes("score")) {
      console.log("name2", name, this.state.date, this.state.date1)
      let data = [], label = []
      let result = await apiService.QuestionCountWithDate(this.state.practitioner_id, "week", this.state.date.toISOString().split('T')[0], this.state.date1.toISOString().split('T')[0])
      console.log("result", result)
      result.map((items, index) => {
        console.log("items", items)
        console.log("lebel", items._id[0].split("T")[0])
        console.log("question", items.questions.length)
        data.push(items.questions[0].length)
        label.push(items._id[0].split("T")[0])
      })
      this.setState({ data, label })
    } else {
      console.log("name3", name, this.state.date, this.state.date1)
    }
  }
  render() {
    const { t } = this.props;
    const { show, date, date1, data, label } = this.state
    return (
      <>
        {this.state.loader ?
          <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />
          :
          <div className="businessinfo-tracking-wrp">
            <div className="row">
              <div className="col-12 col-lg-3">
                <DoughnutCard title={t('title.users_with_tracking')} tracking={this.state.tracking}></DoughnutCard>
              </div>

              <div className="col-12 col-sm-6 col-xl-3">
                {/* <OverviewCard></OverviewCard> */}
                {/* use this "OverviewCard" component with below hard coded data */}
                <div className="overviewcard-wrp">
                  <div className="overviewHead" onClick={() => this.handleOpen("steps")}>
                    <h3>
                      {t('title.daily_average_steps')}
                    </h3>
                    <figure>
                      <img src="/images/lifestyle/steps.svg" className="filterit"></img>
                    </figure>
                    <h4>{t('steps', { num: this.state.step.toFixed(2) })}</h4>
                  </div>
                </div>
              </div>


              <div className="col-12 col-sm-6 col-xl-3">
                {/* use this "OverviewCard" component with below hard coded data */}
                <div className="overviewcard-wrp">
                  <div className="overviewHead" onClick={() => this.handleOpen("questions")}>
                    <h3>
                      {t('title.average_questionnaire_score')}
                    </h3>
                    <figure>
                      <img src="/images/lifestyle/activetime.svg" className="filterit"></img>
                    </figure>
                    <h4>{this.state.avg_question} {t('out_of_100')} </h4>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-xl-3">
                {/* use this "OverviewCard" component with below hard coded data */}
                <div className="overviewcard-wrp">
                  <div className="overviewHead">
                    <h3>
                      {t('title.average_bio_age')}
                    </h3>
                    <figure>
                      <img src="/images/lifestyle/heartrate.svg" className="filterit"></img>
                    </figure>
                    <h4>{this.state.bioAge} {t('years')}</h4>
                  </div>
                </div>
              </div>

            </div>
            <Modal show={show} onHide={() => this.handleClose()} centered className="trackingModal">
              <Modal.Header closeButton>
                <Modal.Title>{this.state.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ display: "flex" }}>
                  <Form.Group controlId="formBasicEmail" style={{ marginRight: "10px" }}>
                    <Form.Label>{t('labels.start_date')}</Form.Label>
                    <div >
                      <DatePicker
                        className="form-control"
                        selected={date}
                        onChange={(e) => this.handleChangeStart(e)}
                        onClickOutside={this.openDatePicker}
                        maxDate={new Date()}
                        placeholderText="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                      ></DatePicker>
                    </div>

                    <Form.Text className="text-muted">

                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" style={{ marginRight: "10px" }}>
                    <Form.Label>{t('labels.end_date')}</Form.Label>
                    <div >
                      <DatePicker
                        className="form-control"
                        selected={date1}
                        onChange={(e) => this.handleChangeEnd(e)}
                        onClickOutside={this.openDatePicker}
                        maxDate={new Date()}
                        placeholderText="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                      ></DatePicker>
                    </div>

                    <Form.Text className="text-muted">

                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" style={{ marginRight: "10px" }}>
                    <Form.Label></Form.Label>
                    <div >
                      <Button onClick={() => this.handleSubmit(this.state.title)} style={{ marginTop: "7px" }}>{t('buttons.submit')}</Button>
                    </div>

                    <Form.Text className="text-muted">

                    </Form.Text>
                  </Form.Group>
                </Form>
                <BarGraph
                  data={data}
                  // xAxis={details.xAxis}
                  labels={label}
                // DetailsGetAPI = {DetailsGetAPI}
                // start_date={start_date}
                // end_date={end_date}
                // selected_type={type}
                ></BarGraph>

                <button className={"continuebtn"} onClick={() => this.setState({ intro: false, show: false })}>{t('buttons.close')}</button>
              </Modal.Body>
            </Modal>
          </div>
        }
      </>
    )
  }
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success
}

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(Tracking);
