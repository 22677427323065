import React from "react";
import Multiselect from "multiselect-react-dropdown";
import { withTranslation } from "react-i18next";
class FilterDropdown extends React.Component {

  constructor(props) {
    super(props)
    this.style = {
      multiselectContainer: {
        width: "260px",
        height: "10px",
      },
      chips: {
        display: "none"
      },
      searchBox: {
        height: "39px"
      }
    }

  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Multiselect
          style={this.style}
          className="filterBtn"
          displayValue="text"
          onSelect={this.props.reportTypeCheckboxChange}
          options={this.props.reportTypes}
          selectedValues={this.props.reportChecked}
          onRemove={this.props.reportTypeCheckboxChange}
          placeholder={t('placeholder.filter_report_type')}
          showCheckbox
          showArrow
        >
        </Multiselect>
      </>
    )
  }
}

export default withTranslation()(FilterDropdown)