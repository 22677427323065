import { alertConstants } from '../constants';

export const alertActions = {
    success,
    error,
    clear,
    warning,
    bulk_alert
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function warning(message) {
    return { type: alertConstants.WARNING, message };
}

function error(message, statusCode) {
    return { type: alertConstants.ERROR, message, statusCode };
}

function clear() {
    return { type: alertConstants.CLEAR };
}

function bulk_alert(data) {
    return { type: alertConstants.BULK_ALERT, data };
}