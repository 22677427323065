import React from 'react';
import { withTranslation } from 'react-i18next';

class WarningIntro extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="intro-text">
                <h6>{t('practitioner_report.note')}</h6><br />
                <h6>{t('practitioner_report.includes')}</h6><br />
                <h6>{t('practitioner_report.proceed')}</h6>
            </div>
        )
    }
}

export default withTranslation()(WarningIntro)