import React from "react";
import { Table, Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import SelectionsBar from "../../components/SelectionsBar/SelectionsBar";
import PageHead from "../../components/PageHead/PageHead";
import LineGraphs from "../../components/LineGraph/LineGraph";

import { apiService } from '../../services/api_service'
import { alertActions } from "../../actions";

class BloodResults extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            users_list: [],
            loader: false,
            message: this.props.t('messages.select_patients'),
            practitioner_id: "",
            email: "",
            viewData: {},
            bloodResult: [],
            fullResult: [],
            sections: [],
            scores: {},
            access: [],
            currentIndex: 0,
            clearUser: false,
            // graph
            graphTitle: "Result",
            scoreList: [],
            sampleList: [],
            currentScore: "",
            currentStatus: "",
            getResultAPICall: false
        }
        this.handleClose = this.handleClose.bind(this)
        this.showGraph = this.showGraph.bind(this)

    }
    handleClose() {
        this.setState({ show: false })
    }

    showGraph(key) {
        const { scores, currentIndex, sections } = this.state;
        console.log(scores, currentIndex, sections);
        console.log({ key, currentIndex }, scores[currentIndex])
        let groupKey = sections[currentIndex]['label']
        let graphData = scores[groupKey][key]
        // console.log("graphData", graphData)

        let scoreList = graphData.map(g => g.round_off_score)
        const totalSamples = graphData.length;
        const currentScore = graphData[totalSamples - 1]['score']
        const currentStatus = graphData[totalSamples - 1]['indicator']
        const graphTitle = graphData[totalSamples - 1]['item_name']
        const sampleList = Array(totalSamples).fill().map((_, index) => index + 1);
        console.log("showGraph state", { show: true, scoreList, sampleList, graphTitle, currentScore, currentStatus });
        this.setState({ show: true, scoreList, sampleList, graphTitle, currentScore, currentStatus })
    }

    async componentWillReceiveProps(nextProps) {
        try {
            if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
                this.setState({ loader: true });
                // let users_list = await apiService.AccessUsersList(
                //   btoa(nextProps.user._id),
                //   "blood"
                // );

                const practitioner_id = btoa(this.props.user._id);
                this.setState({
                    loader: false,
                    practitioner_id,
                    // users_list,
                    ...nextProps.user,
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    async getResult(user_id) {
        const { t } = this.props;

        if (!this.props.user?._id || !user_id) return

        this.setState({ loader: true });
        if (user_id === "select") {
            this.setState({
                sections: [],
                loader: false,
                message: t('messages.select_patients'),
                bloodResult: [],
                fullResult: [],
            });
            return;
        }

        let results = await apiService.GetBloodResult({
            //practitioner_id: btoa(this.props.user?._id),
            user_id: btoa(user_id),
        });

        if (!results.success) {
            console.log("blood result api resp", results);
            this.setState({
                loader: false,
                message: t('no_result_available'),
                sections: [],
                bloodResult: [],
                fullResult: [],
            });
            return;
        }

        if (results.data && results.data.latest) {
            let result = results.data.latest.result;
            console.log("result", result);
            const sections = this.getSections(Object.keys(result));
            const bloodResult = Object.values(result)[0];
            // For graph
            let scores = results.data.scores;
            this.setState({
                loader: false,
                clearUser: false,
                // sections,
                fullResult: result,
                bloodResult,
                scores,
            });
        } else {
            this.setState({
                loader: false,
                message: t('messages.error.no_result_user'),
            });
        }
    }

    getSections(result_sections) {
        const sections = result_sections.map((s, idx) => {
            return { value: idx, label: s }
        })
        this.setState({ sections })
        return sections
    }

    formatKey(str) {
        return str.replace(/[\s\-~!@#$%^&*()+=`{}\[\]|:;"'<>,.?\\/]/g, '_').replace(/_+/g, '_').toLowerCase()
    }

    async selectedItemResult(index) {
        this.setState({ currentIndex: index })
        const { fullResult } = this.state
        if (fullResult && Object.keys(fullResult).length > 0) {
            this.setState({ bloodResult: Object.values(fullResult)[index] })
        }
    }

    formatComment(obs, comment) {

        if (!comment) return comment
        let html = ""
        comment.forEach(item => {
            for (const [key, value] of Object.entries(item)) {
                html += `<p>${value}</p>`
            }
        })
        return html
    }


    render() {
        const { t } = this.props;
        const { show, users_list, bloodResult, viewData, sections, message, clearUser,
            scoreList, sampleList, currentScore, currentStatus, graphTitle } = this.state
        console.log("bloodResult", bloodResult, "sections", sections)
        return (
            <>
                <div className="dnaReport-wrp">
                    <PageHead title={t('title.blood_results')}
                        UserList={users_list}
                        page={'Blood_Results'}
                        clearUser={clearUser}
                        DetailsGetAPI={this.getResult.bind(this)}
                        type="blood"
                    ></PageHead>

                    <SelectionsBar list={sections} title={t('title.blood_results')} changeItem={this.selectedItemResult.bind(this)}></SelectionsBar>

                    <div className="DnaReporttable">

                        <div className="cmnTable">
                            {this.state.loader ?
                                <Spinner animation="border" variant="info" className='spinnerClass' style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />
                                : (bloodResult.length > 0 ?
                                    <Table responsive striped>
                                        <thead>
                                            <tr style={{ color: "white" }}>
                                                <th>{t('blood_table.test')}</th>
                                                <th>{t('blood_table.patient_results')}</th>
                                                <th>{t('blood_table.normal_range')}</th>
                                                <th>{t('blood_table.units')}</th>
                                                <th>{t('blood_table.comment')}</th>
                                                <th>{t('blood_table.action')}</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {bloodResult.map(result => (
                                                <tr key={result.observation_key}>
                                                    <td>{result.observation_name}</td>
                                                    <td>
                                                        {result.observation_value}
                                                    </td>
                                                    <td>
                                                        {result.references_range}
                                                    </td>
                                                    <td>
                                                        {result.units}
                                                    </td>
                                                    <td>
                                                        {/* {this.formatComment(result.comments)} */}
                                                        <div className="result-comment" dangerouslySetInnerHTML={{ __html: this.formatComment(result.observation_name, result.comments) }}></div>
                                                    </td>
                                                    <td><a onClick={() => this.showGraph(result.observation_key)} style={{ cursor: 'pointer' }}>View Graph </a></td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                    : <h5 style={{ textAlign: 'center', padding: '20px' }}>{message}</h5>
                                )
                            }

                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={this.handleClose} centered className="cmnModal epigeneticgraphModal" >
                    <Modal.Header closeButton >
                        <Modal.Title>{graphTitle} {t('title.score')}:</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        {show &&
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="graphCard" >
                                        <LineGraphs
                                            data={scoreList}
                                            label_names={sampleList}
                                            xLabel={"Sample"}
                                            yLabel={"Score"}
                                        ></LineGraphs>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="graphCard" >
                                        <h6>{t('title.status')} : <span className="shealthy" >{currentStatus}</span></h6>
                                        <h3>{currentScore}</h3>
                                    </div>

                                </div>

                            </div>
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    success: alertActions.success
}

export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(BloodResults);