import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { lifestyleTabs } from "../../constants/general-constants";
class OverviewListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: lifestyleTabs(),
      view: lifestyleTabs()[0],
      loader: false,
      practitioner_id: "",
      access: {},
      lifestyleData: {
        step: [603, 2857],
        sleep: [0, 739],
        running: [0, 0],
        walking_times: [0, 59],
        min_heart_rate: [0, 47],
        max_heart_rate: [0, 131],
        avg_heart_rate: [0, 73],
        water_intakes: [0, 0.75],
        calorie_intakes: [0, 0],
        step_count: 1730.0,
        step_distance: 1.5017406,
        step_calorie_burned: 1363.5,
        total_sleep: 369.5,
        walking_time: 29.5,
        running_time: 0.0,
        weights_time: 0.0,
        calorie_burned: 107.5,
        distance: 1.093,
        active: 47.0,
        heart_rate_min: 23.5,
        heart_rate_max: 65.5,
        heart_rate_avg: 36.5,
        heart_rate_resting: 0.0,
        water_intake: 0.375,
        calorie_intake: 0.0,
      },
      users_list: [],
      message: this.props.t('messages.select_patients'),
    };
  }


  ExpandDetails(name) {
    let tab = lifestyleTabs().find(tab => tab.value === name)
    console.log(tab, "ExpanDetails")
    this.props.ChangeTabs(tab);
    this.props.ViewDetailedpage(tab);
  }

  render() {
    const { t } = this.props;
    const { lifestyleDetails } = this.props;

    return (
      <>
        {lifestyleDetails && (
          <div className="overviewList-wrp">
            <div className="row">
              <div className="col-12 col-md-6 col-xl-3">
                <div className="overviewcard-wrp">
                  <div
                    className="overviewHead"
                    name="Steps"
                    onClick={() => this.ExpandDetails("steps")}
                  >
                    <h3>{t('lifestyle_data.steps')}</h3>
                    <figure>
                      <img
                        src="/images/lifestyle/steps.svg"
                        className="filterit"
                        alt="steps"
                      ></img>
                    </figure>
                    <h4> {lifestyleDetails.step_counts} {t('steps')}</h4>
                    <ul className="list-unstyled">
                      <li>
                        {" "}
                        <img src="/images/lifestyle/stepindicator1.svg" alt="step-indicator"></img>
                        {lifestyleDetails.step_calorie_burned}
                      </li>
                      <li>
                        {" "}
                        <img src="/images/lifestyle/stepindicator2.svg" alt="step-indicator"></img>
                        {lifestyleDetails.step_distance}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                {/* use this "OverviewCard" component with below hard coded data */}
                <div className="overviewcard-wrp">
                  <div
                    className="overviewHead"
                    name="sleep"
                    onClick={() => this.ExpandDetails("sleep")}
                  >
                    <h3>{t('lifestyle_data.sleep')}</h3>
                    <figure>
                      <img
                        src="images/lifestyle/sleep.svg"
                        alt="sleep"
                        className="filterit"
                      ></img>
                    </figure>
                    <h4>{lifestyleDetails.total_sleep} {t('minutes')}</h4>
                    <ul className="list-unstyled">
                      <li>
                        {" "}
                        <img src="images/lifestyle/sleepindicator1.svg" alt="sleep-indicator"></img>
                        {lifestyleDetails.sleep_start_time
                          ? lifestyleDetails.sleep_start_time
                          : 10}{" "}
                        {t('hour')}
                      </li>
                      <li>
                        {" "}
                        <img src="images/lifestyle/sleepindicator2.svg" alt="sleep-indicator"></img>
                        {lifestyleDetails.sleep_end_time
                          ? lifestyleDetails.sleep_end_time
                          : 8}{" "}
                        {t('hour')}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                {/* use this "OverviewCard" component with below hard coded data */}
                <div
                  className="overviewcard-wrp"
                  name="Active Time"
                  onClick={() => this.ExpandDetails("active_time")}
                >
                  <div className="overviewHead">
                    <h3>{t('lifestyle_data.active_time')}</h3>
                    <figure>
                      <img
                        src="images/lifestyle/activetime.svg"
                        alt="activetime"
                        className="filterit"
                      ></img>
                    </figure>
                    <h4>{lifestyleDetails.active}</h4>
                    <ul className="list-unstyled">
                      <li>
                        {" "}
                        <img src="images/lifestyle/acttivetimeindicator1.svg" alt="activetime-indicator"></img>
                        {lifestyleDetails.walking_time} min{" "}
                      </li>
                      <li>
                        {" "}
                        <img src="images/lifestyle/acttivetimeindicator2.svg" alt="activetime-indicator"></img>
                        {lifestyleDetails.running_time !== 0
                          ? lifestyleDetails.running_time
                          : 30}{" "}
                        min{" "}
                      </li>
                      <li>
                        {" "}
                        <img src="images/lifestyle/acttivetimeindicator3.svg" alt="activetime-indicator"></img>
                        {lifestyleDetails.weights_time !== 0
                          ? lifestyleDetails.weights_time
                          : 45}{" "}
                        min{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                {/* use this "OverviewCard" component with below hard coded data */}
                <div
                  className="overviewcard-wrp"
                  name="Heart Rate"
                  onClick={() => this.ExpandDetails("heart_rate")}
                >
                  <div className="overviewHead">
                    <h3>{t('lifestyle_data.heart_rate')}</h3>
                    <figure>
                      <img
                        src="images/lifestyle/heartrate.svg"
                        alt="heartrate"
                        className="filterit"
                      ></img>
                    </figure>
                    <h4>{lifestyleDetails.heart_rate_avg} {t('bpm')}</h4>
                    <ul className="list-unstyled">
                      <li>
                        {" "}
                        <img src="images/lifestyle/sleepindicator1.svg"></img>
                        {lifestyleDetails.heart_rate_max} {t('bpm')}
                      </li>
                      <li>
                        {" "}
                        <img src="images/lifestyle/sleepindicator2.svg"></img>
                        {lifestyleDetails.heart_rate_min} {t('bpm')}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                {/* use this "OverviewCard" component with below hard coded data */}
                <div
                  className="overviewcard-wrp"
                  name="Water Intake"
                  onClick={() => this.ExpandDetails("water_intake")}
                >
                  <div className="overviewHead">
                    <h3>{t('lifestyle_data.water_intake')}</h3>
                    <figure>
                      <img
                        src="images/lifestyle/waterintake.svg"
                        className="filterit"
                      ></img>
                    </figure>
                    <h4>{lifestyleDetails.water_intake} ml</h4>
                    {/* <ul className="list-unstyled">
                                        <li> <img src="images/lifestyle/waterindicator1.svg"></img>2500 ml</li>
                                        <li> <img src="images/lifestyle/waterindicator2.svg"></img>81%</li>
                                    </ul> */}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                {/* use this "OverviewCard" component with below hard coded data */}
                <div
                  className="overviewcard-wrp"
                  name="Calorie Intake"
                  onClick={() => this.ExpandDetails("calorie_intake")}
                >
                  <div className="overviewHead">
                    <h3>{t('lifestyle_data.calorie_intake')}</h3>
                    <figure>
                      <img
                        src="images/lifestyle/calorieintake.svg"
                        className="filterit"
                      ></img>
                    </figure>
                    <h4>{t('lifestyle_measurements.kcal', { num: lifestyleDetails.calorie_intake })} </h4>
                    {/* <ul className="list-unstyled">
                                        <li> <img src="images/lifestyle/caloriesintake1.svg"></img>1000 kcal</li>
                                        <li> <img src="images/lifestyle/caloriesintake2.svg"></img>287 kcal</li>
                                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  // success: alertActions.success,
};

// export default connect(mapState, actionCreators)(OverviewListing);
export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(OverviewListing);