import { React } from "react";
import PieChart from "../../components/Graphs/pieChart/pieChart";


const OverviewDetails = ({ overview, tabs, categorySelector }) => {
    return (
        <>
            <div className="reportDetail-wrp">
                <div style={{ display: "flex" }}>
                    {overview && overview.map((items, index) => (
                        <div key={index} style={{ display: "flex", flexDirection: "column", width: "calc(100%/4)" }} onClick={() => categorySelector(tabs[index])}>
                            <h5 style={{ fontSize: 15, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingBottom: "15px", cursor: "pointer" }}>{tabs[index].title}</h5>
                            <PieChart className="pie-chart-overview" summary={items} num={items.score} />
                            <p style={{ fontSize: 15, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingTop: "15px", cursor: "pointer" }}>{items.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default OverviewDetails
