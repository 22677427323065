import { React } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

const UserMenu = (props) => {
  const { user } = useSelector((state) => state.authentication);
  const { t } = props;

  return (
    <>
      <div className="userDetails">
        <h6>{`${user?.first_name || ""} ${user?.last_name || ""}`}</h6>
        <Dropdown className="customDropdown">
          <Dropdown.Toggle>
            <figure>
              <img className="img-fluid" src="/images/user.png"></img>
            </figure>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={props.modal()}>{t('profile')}</Dropdown.Item>
            <Dropdown.Item onClick={props.logout()}>{t('logout')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default withTranslation()(UserMenu);
