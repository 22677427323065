import { React } from "react";
import { Table } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import { generateReferenceLink } from "../../helpers/generateReferenceLink";

const CongenitalDetails = ({ data, t }) => {

    return (
        <>
            <div className="reportDetail-wrp">
                <div className="cmnTable">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>{t('rsid_table.rsid')}</th>
                                <th>{t('rsid_table.genotype_index')}</th>
                                <th>{t('rsid_table.reference')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 ? (
                                data.map((item) => (
                                    <tr key={item.rsid}>
                                        <td>{item.rsid}</td>
                                        <td style={item.index[0] !== item.index[1] ? { color: 'red', fontWeight: 'bold' } : {}}>
                                            {item.index}
                                        </td>
                                        <td>
                                            <a href={generateReferenceLink(item.rsid)} target="__blank" className="reference-link">
                                                {generateReferenceLink(item.rsid)}
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center', color: 'grey' }}>
                                        {t('rsid_table.no_data_found')}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                </div>
            </div>
        </>
    )
}

export default withTranslation()(CongenitalDetails)
