import i18next from 'i18next'; // Import i18next for translations

export function validatePasswordCriteria(password) {
    const criteria = [
        { name: i18next.t("password_criteria.uppercase"), pattern: /(?=.*[A-Z])/ },
        { name: i18next.t("password_criteria.lowercase"), pattern: /(?=.*[a-z])/ },
        { name: i18next.t("password_criteria.digit"), pattern: /(?=.*\d)/ },
        { name: i18next.t("password_criteria.special_chars"), pattern: /(?:[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*){2}/ },
        { name: i18next.t("password_criteria.length"), pattern: /^.{15,}$/ },
    ];

    return criteria
        .filter((criterion) => !criterion.pattern.test(password))
        .map((criterion) => criterion.name);
}
