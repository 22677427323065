import React from "react";
import { Spinner, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { apiService } from '../../services/api_service'
import { alertActions } from "../../actions";

import PageHead from "../../components/PageHead/PageHead";

class VirusRisk extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            users_list: [],
            loader: false,
            practitioner_id: this.props.user?.id,
            email: this.props.user?.email,
            virusResult: [],
            message: this.props.t('messages.select_patients'),
            key: '',
            result: {},
            access: [],
            resultNotify: [],
            clearUser: false
        }
    }
    /*
      async componentDidMount() {
          this.setState({ loader: true })
          try {
  
              let user_details = await apiService.AccessUsersList(this.state.practitioner_id)
              // let listData=[]
              // await user_details.map(users =>{
              //     listData.push({
              //         label:users.first_name + users.last_name,
              //         value:users._id,
              //         kit_id:user.kit_id
              //     })
  
              // })
              this.setState({
                  users_list: user_details,
                  loader: false,
              })
          } catch (error) {
              console.log("error", error)
          }
  
      }
      */

    async componentWillReceiveProps(nextProps) {
        try {
            if (nextProps.user?._id && nextProps.user._id !== this.state._id) {
                this.setState({ loader: true });
                const practitioner_id = btoa(nextProps.user._id);
                //const users_list = await apiService.AccessUsersList(practitioner_id);

                this.setState({
                    //users_list,
                    loader: false,
                    practitioner_id,
                    practitioner_details: nextProps.user,
                    ...nextProps.user,
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    indicator(val) {
        switch (val) {
            case "Red":
                return { status: 'Unhealthy', indicator: 1, class: 'sHealthy' }
            case 'Yellow':
                return { status: 'Normal', indicator: 3, class: 'sUnhealthy' }
            case 'Green':
                return { status: 'Healthy', indicator: 5, class: 'sHealthy' }
            default:
                return { status: 'Normal', indicator: 1, class: 'sHealthy' }
        }
    }

    async getResult(user_id) {
        const {t} = this.props;
        if (!this.props.user?._id || !user_id) return
        this.setState({ loader: true })
        if (user_id == 'select') {
            this.setState({ loader: false, message: t('messages.select_patients'), virusResult: [], resultNotify: [] })
            return
        }
        let userId = Buffer.from(String(user_id)).toString('base64')
        let access = this.state.access
        if (this.state.access == undefined || Object.keys(this.state.access).length <= 0) {
            let practitioner_details = await apiService.practitionerDetails()
            access = practitioner_details.access_controls.Virus_risk
        }

        const practitioner_id = btoa(this.props.user?._id)
        let Risk = await apiService.VirusRisk(practitioner_id, userId)
        if (!Risk.success && Risk.message == 'user not found') {
            this.setState({ clearUser: true, loader: false })
            return;
        } else {
            this.setState({ clearUser: false })
            Risk = Risk.data || Risk.message;
        }
        let resultNotify = []
        let virusResult = []
        let message = ""
        if (typeof Risk == "string") {
            console.log("string")
            resultNotify.push({ status: true, message: Risk })
            message = Risk
        } else {
            Object.entries(Risk).map((items) => {
                console.log("items", items[1])
                if (typeof items[1].status === "boolean") {
                    resultNotify.push(items[1])
                } else {
                    virusResult.push(items[1])
                }

            })
        }

        this.setState({ loader: false, virusResult, resultNotify, access, message })
    }

    render() {
        const { t } = this.props;
        const { users_list, virusResult, message, resultNotify, access, clearUser } = this.state
        return (
            <>

                <div className="dnaReport-wrp">
                    <PageHead title={t('heading.virus_risk_score')}
                        UserList={users_list}
                        clearUser={clearUser}
                        page={'Virus_Report'}
                        DetailsGetAPI={this.getResult.bind(this)}
                    ></PageHead>

                    <div className="selectionBar">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-sm-auto">
                                <h3>{t('heading.virus_risk_score')}</h3>
                            </div>
                        </div>
                    </div>


                    <div className="epigeneticReporttable">

                        <div className="cmnTable">
                            {this.state.loader ?
                                <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />
                                : (virusResult.length > 0 ?
                                    <Table responsive striped>
                                        <thead>
                                            <tr style={{ color: "white" }}>
                                                <th>{t('result_table.title')}</th>
                                                <th>{t('result_table.indicator')}</th>
                                                <th>{t('result_table.score')}</th>
                                                <th>{t('result_table.status')}</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {virusResult.map(result => (
                                                <>
                                                    {
                                                        access[result.title.replace(/ /g, "_").toLocaleLowerCase()] ?
                                                            <tr>
                                                                <td>{result.title}</td>
                                                                <td>{result.indicator}</td>
                                                                <td>{result.score}</td>
                                                                <td>{camelize(result.status)}</td>
                                                            </tr>
                                                            : null
                                                    }

                                                </>
                                            ))}
                                            {
                                                resultNotify.map((items) => (
                                                    !items.status ?
                                                        (<>
                                                            <tr>
                                                                <td colSpan={4} style={{ color: "yellowgreen" }}>{items.message}</td>
                                                                <td>{items.status}</td>
                                                            </tr>
                                                        </>) :
                                                        null
                                                ))
                                            }
                                            <p style={{ textAlign: 'left', padding: '20px', color: "red" }}>{t('text.virus_risk_note')}</p>
                                        </tbody>
                                    </Table> : <h5 style={{ textAlign: 'center', padding: '20px' }}>{message}</h5>
                                )
                            }
                        </div>

                    </div>
                </div>
            </>
        )
    }
}
function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toUpperCase();
    }).replace(/\s+/g, ' ');
}


function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    success: alertActions.success
}

export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(VirusRisk);


