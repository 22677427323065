import React from "react";
import { Form, Modal, Button, Spinner, } from "react-bootstrap";
import { connect } from 'react-redux';
import CSVReader from "react-csv-reader";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { apiService } from '../../services/api_service'
import { alertActions } from '../../actions';
import { store } from '../../helpers'

class BulkUploadModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show1: props.show ? props.show : false,
            error: {
                csv: false,
            },
            csvErrMsg: this.props.t('validations.kit_id'),
            loader: false,
            showAlert: false,
            alert: {
                message: 'Error while update user',
                type: 'alert-danger'
            },
            kits: [],
            submitted: false
        }
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.errorValidation = this.errorValidation.bind(this)
    }

    errorValidation() {
        if (this.state.kits.length == 0) {
            this.setState({ csvErrMsg: this.props.t('validations.csv_file'), error: { ...this.state.error, csv: true } })
            return false
        }
        return true
    }

    async handleUpload(data, fileName) {
        console.log(data, fileName);
        let kits = [];
        for (let kit of data) {
            if (!kit.kit_id) break

            kits.push(kit.kit_id)
        }
        console.log("kits", kits)
        this.setState({ kits: kits })
    }

    async handleSubmit(e) {
        e.preventDefault();

        if (!this.errorValidation()) {
            return;
        }

        let body = {
            kits: this.state.kits.map(k => k.toUpperCase()),
        }

        this.setState({ submitted: true });

        let uploadResp = await apiService.BulkUploadBloodKit(body)

        this.setState({ submitted: false })
        this.props.closeModal()
        if (uploadResp.status === 200) {
            setTimeout(() => {
                store.dispatch(alertActions.success(uploadResp.message))
            }, 1500)
            this.props.updateAPI()
            return
        }

        setTimeout(() => {
            store.dispatch(alertActions.error(uploadResp.message || this.props.t('messages.error.error_upload_kits')))
        }, 1500)

    }

    handleClose = () => {
        // this.props.clearAlert();
        this.setState({
            kits: [],
            error: { csv: false }
        })
        this.props.closeModal()
    }

    render() {
        const { error, csvErrMsg, showAlert, alert } = this.state
        const { show, title, t } = this.props
        const csvParseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
        }
        return (
            <>
                <Modal show={show} onHide={this.handleClose} centered className="userModal" size="sm" >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form autoComplete='off'>
                            {showAlert &&
                                <div className={`alert ${alert.type}`} style={{ marginBottom: 15 }}>
                                    {alert.message}
                                    <button className="close" onClick={this.handleClose} >x</button>
                                </div>
                            }
                            <CSVReader
                                cssClass="react-csv-input"
                                label={t('labels.select_csv_file')}
                                onFileLoaded={this.handleUpload}
                                parserOptions={csvParseOptions}
                            />
                            <div className="smDownLink">{t('sample_file')} - <a download="sample.csv" target="_blank" href="sampleFile/kitsample.csv" style={{ color: "#007bff", }}>{t('download_link')}</a></div>
                            <Form.Text className="text-muted">
                                {error.csv ? csvErrMsg : null}
                            </Form.Text>
                            <Button className="continuebtn" type="submit" size='sm' onClick={this.handleSubmit}>
                                {this.state.submitted ? <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style={{ float: 'center' }} /> : t('buttons.submit')}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
}

export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(BulkUploadModal);
