import React from "react";
import { Bar } from "react-chartjs-2";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { apiService } from '../../services/api_service'
import { alertActions } from "../../actions";

import DoughnutCard from "../../components/Graphs/DoughnutCard/DoughnutCard";
import UserRegisteredcountCard from "../../components/UserRegisteredcountCard/UserRegisteredcountCard";

class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data1: {
        labels: [
          "18 - 24",
          "25 - 30",
          "31 - 35",
          "36 - 40",
          "41 - 45",
          "46 - 50",
          "50+",
        ],
        datasets: [
          {
            label: 'Male',
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: "#8AD4F1",
            maxBarThickness: 40,
          },
          {
            label: 'Female',
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: "#8AD4F1",
            maxBarThickness: 40,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              font: {
                size: 14,
              },
            },
            title: {
              color: "1E1E1E",
              display: true,
              text: "Users",
            },
          },
          x: {
            title: {
              color: "1E1E1E",
              display: true,
              text: "Age Range",
            },
          },
        },
      },
      practitioner_id: "",
      users: {},
      ageCount: {},
      products: {},
      loader: false
    }

    this.init = this.init.bind(this);
  }

  async componentDidMount() {
    if (this.props.user?._id) {
      this.init(this.props)
    }
  }

  async componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        this.init(nextProps)
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  async init(nextProps) {
    this.setState({ loader: true });

    const practitioner_id = btoa(nextProps.user?._id);

    let users = await apiService.UsersCount(practitioner_id);
    let ageCount = await apiService.ageRange(practitioner_id);
    let products = await apiService.productCount(practitioner_id);

    console.log("products", products);
    Object.entries(products).forEach((items) => {
      console.log(items[1]);
      if (items[0].includes("dna")) {
        console.log("items[0]", items[0]);
        products[items[0]] = Math.floor(items[1]);
      } else {
        delete products[items[0]];
      }
    });

    let datasetMaleData =
      ageCount.length > 0
        ? [
          ageCount[0].first_male,
          ageCount[0].second_male,
          ageCount[0].third_male,
          ageCount[0].fourth_male,
          ageCount[0].fifth_male,
          ageCount[0].sixth_male,
          ageCount[0].seventh_male,
        ]
        : [];
    let datasetFemaleData =
      ageCount.length > 0
        ? [
          ageCount[0].first_female,
          ageCount[0].second_female,
          ageCount[0].third_female,
          ageCount[0].fourth_female,
          ageCount[0].fifth_female,
          ageCount[0].sixth_female,
          ageCount[0].seventh_female,
        ]
        : [];

    const data1 = {
      ...this.state.data1,
      datasets: [
        {
          label: "Male",
          data: datasetMaleData,
          backgroundColor: "#8AD4F1",
          maxBarThickness: 40,
        },
        {
          label: "Female",
          data: datasetFemaleData,
          backgroundColor: "#ecb3ff",
          maxBarThickness: 40,
        },
      ],
    }

    this.setState({
      practitioner_id,
      users,
      ageCount,
      products,
      data1,
      loader: false,
      ...nextProps.user,
    });
  }

  render() {
    const { t } = this.props;
    const { data1, options, users, products, loader } = this.state
    return (
      <>
        {loader ?
          <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />

          :
          <div className="businessinfo-user-wrp">
            <div className="row">
              <div className="col-12 col-lg-6">
                <UserRegisteredcountCard userDetail={users}></UserRegisteredcountCard>
                <DoughnutCard title={t('title.products')} products={products}></DoughnutCard>
              </div>

              <div className="col-12 col-lg-6">
                {/* use this for Bar chart */}
                {/* <BarGrapgh></BarGrapgh> */}
                {/* use this for Bar chart */}
                <div className="graph-wrp">
                  <h5>{t('title.age_range')}</h5>
                  <div className="barchart-wrp cmnchart-wrp">
                    <Bar data={data1} options={options} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }

}


function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success
}


var numberWithCommas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// export default connect(mapState, actionCreators)(Users)
export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(Users);