import { currentConstants } from '../constants';

export const currentActions = {
    add,
    remove
};


function add(id, email, page) {
    let users = { "value": id, "label": email, "page": page }
    sessionStorage.setItem("current", JSON.stringify(users))
    return { type: currentConstants.ADD, users }

}

function remove(id = "", email = "", page) {
    sessionStorage.setItem("current", "")
    return { type: currentConstants.REMOVE, users: { "value": id, "label": email, "page": page } }
}