import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Loader from "../../components/Loader/Loader";
import { alertActions } from '../../actions';

class SetPassword extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            heightCm: "165",
            heightFt: "5",
            heightIn: "5",
            heightUnit: "cm",
            weightUnit: "lbs",
            weightLbs: "164",
            weightKg: "74",
            weightGm: "8",
            user_id: "",
            email: "",
            gender: "",
            submitted: false,
            hide: true,
            loading: false,
            passwordError: false,
            passwordFormat: false,
            hide1: true,
            confirmError: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillMount() {
        this.setState({ loading: true })
    }

    async componentDidMount() {
        // set default color
        document.getElementById("bodyID").style.setProperty('--themecolor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--themefontcolor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--filterSvg', '');
        
        this.setState({
            loading: false
        });
        console.log("props", this.props)

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    async handleSubmit(e) {
        this.setState({ submitted: true });
        const { heightCm, heightIn, heightFt, heightUnit, weightUnit, weightKg, weightGm, weightLbs, gender } = this.state;
        console.log("weightGm", weightGm)
        let data = {
            height: heightUnit == "cm" ? heightCm : parseInt(heightFt) + (0.0833 * parseInt(heightIn)),
            weight: weightUnit == "lbs" ? weightLbs : weightKg + "." + weightGm,
            height_unit: heightUnit,
            weight_unit: weightUnit,
            gender
        }

        console.log("data for api", data);
    }

    handleClose = () => {
        this.props.clearAlerts();
    }
    render() {
        const { heightCm, heightFt, heightIn, heightUnit, weightUnit, weightLbs, weightKg, weightGm, submitted, loading } = this.state;
        const { alert, t } = this.props;
        return (
            <div className="formPart forgotForm">
                {alert.message &&
                    <div className={`alert ${alert.type}`} style={{ marginBottom: 0 }}>
                        {alert.message}
                        <button className="close" onClick={this.handleClose} >x</button>
                    </div>
                }
                <Loader loading={loading} />
                <h1>{t('heading.complete_signup')}</h1>
                <h6>{t('signup_enter_details')}</h6>
                <Form>
                    <Form.Label>{t('labels.height')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        {heightUnit == "cm" ?
                            <Form.Control placeholder={t('placeholder.height')}type="number" min={12} max={275} name="heightCm" value={heightCm} required onChange={this.handleChange} />
                            :
                            <>
                                <Form.Control placeholder={t('placeholder.height')}type="number" min={1} max={8} name="heightFt" value={heightFt} required onChange={this.handleChange} />
                                <Form.Control placeholder={t('placeholder.height')}type="number" min={0} max={11} name="heightIn" value={heightIn} required onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </>
                        }
                        {/* <Form.Check inline type='radio' name="heightUnit" value="cm" label="cm" onChange={this.handleChange}/>
                        <Form.Check inline type='radio' name="heightUnit" value="ft" label="ft" onChange={this.handleChange} /> */}
                        <select name="heightUnit" onChange={this.handleChange}>
                            <option>{t('height_options.cm')}</option>
                            <option>{t('height_options.ft')}</option>
                        </select>
                    </Form.Group>
                    <Form.Label>{t('labels.weight')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        {weightUnit == "lbs" ?
                            <Form.Control placeholder={t('placeholder.weight')} type="number" min={12} max={275} name="weightLbs" value={weightLbs} required onChange={this.handleChange} />
                            :
                            <>
                                <Form.Control inline="true" placeholder={t('placeholder.weight')} type="number" min={1} max={200} name="weightKg" value={weightKg} required onChange={this.handleChange} />
                                <Form.Control inline="true" placeholder={t('placeholder.weight')} type="number" min={0} max={9} name="weightGm" value={weightGm} required onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </>
                        }
                        {/* <Form.Check inline type='radio' name="weightUnit" value="kg" label="kg" onChange={this.handleChange} />
                        <Form.Check inline type='radio' name="weightUnit" value="lbs" label="lbs" onChange={this.handleChange} /> */}
                        <select name="weightUnit" onChange={this.handleChange}>
                            <option value="lbs">{t('weight_options.lbs')}</option>
                            <option value="kg">{t('weight_options.kg')}</option>
                        </select>
                    </Form.Group>
                    <Form.Label>{t('labels.gender')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        <Form.Check inline type='radio' name="gender" value="male" label={t('gender.male')} onChange={this.handleChange} />
                        <Form.Check inline type='radio' name="gender" value="female" label={t('gender.female')} onChange={this.handleChange} />
                    </Form.Group>
                    <Button className="continuebtn" onClick={this.handleSubmit}>
                        {t('buttons.submit')}
                    </Button>
                </Form>
            </div>
        );
    }
};

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    success: alertActions.success,
    error: alertActions.error
}

export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(SetPassword);