import React from "react";
import { Button } from "react-bootstrap";
import { FcBrokenLink } from "react-icons/fc";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { alertActions, userActions } from "../../actions";
import UserHeader from "../../components/UserHeader/UserHeader";

class LinkExpired extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerId: null
    };

  }
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const partnerId = Number(searchParams.get('partner_id'));
    this.setState({ partnerId });
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <UserHeader partner_id={this.state.partnerId}></UserHeader>
        <div className="expire-wrp">
          <div>
            <h1>{t('title.oops')}</h1>
          </div>
          <div>
            <FcBrokenLink size={250}></FcBrokenLink>
            <div className="expire-text">
              {t('messages.link_expired')}
            </div>
            <Button
              class="continuebtn btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/signin");
              }}
            >
              {t('buttons.back_to_login')}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  otp: userActions.otp,
  login: userActions.login,
  error: alertActions.error,
}

// export default connect(mapState, actionCreators)(LinkExpired);
export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(LinkExpired);
