import i18next from 'i18next';

export const regexKit = /^(?=.{4,})(?=.*[0-9])(?=.*[A-Z])([A-Z0-9]+)$/;

export const otpErrorMessages = {
  wrong_otp_attempt_exceed: i18next.t("otp_validation.wrong_otp_attempt_exceed"),
  link_expired1: i18next.t("otp_validation.link_expired1"),
  link_expired2: i18next.t("otp_validation.link_expired2"),
  invalid_otp: i18next.t("otp_validation.invalid_otp"),
};

export const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;

export const getDnaSections = () => [
  { label: i18next.t('dna_sections.physical'), value: 0 },
  { label: i18next.t('dna_sections.diet'), value: 1 },
  { label: i18next.t('dna_sections.vitamins'), value: 2 },
  { label: i18next.t('dna_sections.health'), value: 3 },
]

export const getHISections = () => [
  { value: "STRESS", label: i18next.t('hi_sections.stress') },
  { value: "SLEEP", label: i18next.t('hi_sections.sleep') },
  { value: "AGEING", label: i18next.t('hi_sections.ageing') },
  { value: "INJURY_PREVENTION", label: i18next.t('hi_sections.injury') },
  { value: "MENTAL_HEALTH", label: i18next.t('hi_sections.mental_health') },
  { value: "GUT_HEALTH", label: i18next.t('hi_sections.gut_health') },
  { value: "HEART_HEALTH", label: i18next.t('hi_sections.heart_health') },
  { value: "IMMUNITY", label: i18next.t('hi_sections.immunity') },
  { value: "EYE_HEALTH", label: i18next.t('hi_sections.eye_health') },
  { value: "SKIN_HEALTH", label: i18next.t('hi_sections.skin_health') },
  { value: "MUSCLE_HEALTH", label: i18next.t('hi_sections.muscle_health') },
]

export const getDnaQuestionnaireCategory = () => [
  { "name": "Diet", "value": "diet" },
  { "name": "General", "value": "general" },
  { "name": "Lifestyle", "value": "lifestyle" },
  { "name": "Exercise", "value": "exercise" },
];

export const getEpiQuestionnaireCategory = () => [
  { "name": "Health", "value": "health" },
  { "name": "Exercise", "value": "exercise" },
  { "name": "Diet", "value": "diet" },
  { "name": "Vitamins Supplements", "value": "vitamins_supplements" },
  { "name": "Lifestyle & Environment", "value": "lifestyle_&_environment" },
  { "name": "Medications", "value": "medications" },
]

// export const getUserType = () => [
//   { label: i18next.t('user_types.all'), value: "all" },
//   { label: i18next.t('user_types.admin'), value: "admin" },
//   { label: i18next.t('user_types.partner'), value: 'partner' },
//   { label: i18next.t('user_types.clinic'), value: 'clinic' },
//   { label: i18next.t('user_types.practitioner'), value: 'practitioner' },
// ]

export const getBrainAgeType = () => [
  { label: i18next.t('brain_age_type.all'), value: 0, text: "All" },
  { label: i18next.t('brain_age_type.assessment'), value: 1, text: "ASSESSMENT" },
  { label: i18next.t('brain_age_type.training'), value: 2, text: "TRAINING" },
];

export const getSearchBarOption = () => { return { value: "select", label: i18next.t('placeholder.type_to_search') } }

export const SortArray = (arr = [], keyName = "", ...args) => {
  if (!arr || !arr.length) return [];

  if (!keyName) {
    keyName = "flName";
  }

  try {
    const sortedArray = [...arr].sort((a, b) => {
      const nameA =
        keyName === "flName"
          ? `${a.first_name} ${a.last_name}`.toLowerCase().trim()
          : a?.[keyName]
            ? `${a[keyName]}`.trim()
            : "";

      const nameB =
        keyName === "flName"
          ? `${b.first_name} ${b.last_name}`.toLowerCase().trim()
          : b?.[keyName]
            ? `${b[keyName]}`.trim()
            : "";

      return nameA.localeCompare(nameB);
    });

    return sortedArray;
  } catch (error) {
    throw error;
  }
};

export const nameRegex = /^[-a-zA-Z0-9.'\p{L}\s]{1,30}$/u;

export const usernameRegex = /^[a-zA-Z0-9._\p{L}\s-]*$/u;

export const genderList = () => [
  { label: i18next.t('gender.female'), key: 0, value: "female" },
  { label: i18next.t('gender.male'), key: 1, value: "male" }
];

export const languageList = [
  { label: "English", value: "en" },
  { label: "German", value: "de" },
  { label: "Lithuanian", value: "lt" },
  { label: "Dutch", value: "nl" },
  { label: "Polish", value: "pl" },
  { label: "Japanese", value: "ja" },
]

export const translationLanguages = [
  { label: "English", value: "en" },
  { label: "Türkçe", value: "tr" },
  { label: "Romanian", value: "ro" }
]

export const RESULT_TYPE_KIT_STATUS = [
  'RESULT_READY_DNA',
  'RESULT_READY_EPI',
  'FAILED_QC_DNA',
  'FAILED_QC_EPI'
]

export const practitionerReportTabs = () => [
  { label: i18next.t('pr_tabs.overview'), value: 1 },
  { label: i18next.t('pr_tabs.details'), value: 2 },
  { label: i18next.t('pr_tabs.summary'), value: 3 }
]

export const businessInsightTabs = () =>
  [
    { value: "users", label: i18next.t('bi_tabs.users') },
    { value: "engagement", label: i18next.t('bi_tabs.engagement') },
    { value: "tracking", label: i18next.t('bi_tabs.tracking') }
  ];

export const lifestyleTabs = () => [
  { "value": "overview", label: i18next.t('lifestyle_tabs.overview') },
  { "value": "steps", label: i18next.t('lifestyle_tabs.steps') },
  { "value": "sleep", label: i18next.t('lifestyle_tabs.sleep') },
  { "value": "active_time", label: i18next.t('lifestyle_tabs.active_time') },
  { "value": "heart_rate", label: i18next.t('lifestyle_tabs.heart_rate') },
  { "value": "water_intake", label: i18next.t('lifestyle_tabs.water_intake') },
  { "value": "calorie_intake", label: i18next.t('lifestyle_tabs.calorie_intake') },
]

export const devicesList = () => [
  i18next.t('android'),
  i18next.t('ios'),
  i18next.t('others')
]

export const resultsTypes = () => [
  { value: "DNA", label: i18next.t('bi_results_types.DNA') },
  { value: "EPI", label: i18next.t('bi_results_types.EPI') },
  { value: "DNA_EPI", label: i18next.t('bi_results_types.DNA_EPI') },
  { value: "others", label:  i18next.t('bi_results_types.others') }
]