import axios from 'axios';
import config from '../config/config';
import { authHeader, store } from '../helpers';
import { userActions } from '../actions';

// const config = {
//     apiUrl: "https://dev-api.muhdo.com/"
// }


export const userService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    otp,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    practitioner_logout
};

function login(username, password) {
    const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email:username, password:password })
        };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.data){
               return user.data;
            }else{
                throw user.message;
            }
        });
}

async function logout(page, alert) {
  // remove user from local storage to log user out
  await practitioner_logout(page, alert)
}

function forgotPassword(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email:username })
    };
    console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/forgotpassword`, requestOptions)
        .then(handleResponse)
        .then(forgot => {
            if(forgot.statusCode===200){
                return forgot;
            }else{
                throw forgot.message;
            }
        });
}

async function resetPassword(password, token) {
    try {
      const body = JSON.stringify({ password });
  
      let res = await axios.post(
        `${config.apiUrl}practitioner/resetpassword`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    
    } catch (error) {
      return error?.response;
    }
  }

async function otp(otp, token) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ OTP: otp })

    };
    // console.log(requestOptions,'requestOptions')

    const res = await fetch(`${config.apiUrl}practitioner/verifyOTP`, requestOptions);
    const responseBody = await res.json();


    if (res.status === 400) {
        console.log("Response Body:", res.statusText, responseBody);
        throw responseBody;
    }

    let userDetails = responseBody.data
    const userItem = { user_name: userDetails.user_name, name: userDetails.first_name + " " + userDetails.last_name, email: userDetails.email, type: userDetails.type, id: btoa(userDetails._id), token: userDetails.token, partner_id: userDetails.partner_id }
    sessionStorage.setItem('token', userItem.token)
    return responseBody;
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].includes(response.status) ) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

async function practitioner_logout(page = "", alert = () => {}) {
  let res = { status: 204 };

  const token = sessionStorage.getItem('token');
  if (token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    res = await fetch(`${config.apiUrl}practitioner/logout`, requestOptions);
  }

  if (res.status === 204) {
    sessionStorage.clear();
    localStorage.clear();
    store.dispatch(userActions.logout());

    if (!page) page = "/signin";

    setTimeout(() => {
      alert();
    }, 10);

    window.history.pushState({}, "", page);
    window.location.reload(page);
    return true;
  }

  return false;
}
