import React from "react";
import { Table, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import moment from "moment";

class ContentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { questions, loader, message, t } = this.props;
        const answersAvailable = questions && questions.answers && questions.answers.length > 0;

        return (
            <div className="cmnTable">
                <Table responsive striped>
                    <thead>
                        <tr>
                            {questions && questions.text && questions.text.length > 0 && <th>Questions</th>}
                            {answersAvailable && questions.answers[0].map((ans, i) => (
                                <th key={i}>
                                    {i === 0 ? t('current_answers') : t('previous_answers')}<br />
                                    {ans.createdAt && <small>({moment(ans.createdAt).format("DD-MM-YYYY")})</small>}
                                </th>
                            ))}
                            {answersAvailable && questions.answers[0].length === 0 &&
                                <th>{t('current_answers')}<small></small></th>
                            }
                        </tr>
                    </thead>
                    {!loader && answersAvailable &&
                        <tbody>
                            {questions.key && questions.key.map((k, i) => (
                                <tr key={k}>
                                    <td>{questions.text[i]}</td>
                                    {questions.answers[i] && questions.answers[i].length > 0 ? (
                                        questions.questionAnswer[i].map((ansGroup, j) => (<td key={j}>
                                            {ansGroup.map(e => e.text).join(",")}
                                        </td>
                                        ))
                                    ) : (
                                        <td colSpan={questions.answers[0].length + 1}>{t('no_answers')}</td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    }
                </Table>
                {(!questions || questions.length === 0) && !loader &&
                    <h5 style={{ textAlign: 'center', paddingTop: '20px' }}>{message}</h5>
                }
                {loader && <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />}
            </div>
        );
    }
}

export default withTranslation()(ContentTable);
