import { React } from "react";
import PieChart from "../../components/Graphs/pieChart/pieChart";
import { withTranslation } from "react-i18next";

const ReportSummary = ({ num, data, summary, t }) => {

    let line_data = [0]
    let label_names = [""]
    data.map((item) => {
        if (item.value.num !== undefined) {
            line_data.push(item.value.num)
            label_names.push(item.rsid)
        } else {
            line_data.push(0)
            label_names.push(item.rsid)
        }
    })

    return (
        <>
            <div className="reportSummary-wrp">
                <div className="row">
                    <div className="col-lg-6">
                        <div style={{ display: "flex" }}>
                            <PieChart summary={summary} num={num} />
                            <p style={{ fontSize: 15, fontWeight: 500, marginTop: "5%" }}>{summary.title}</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>
                <hr />
                <h6>{t('title.summary')}</h6>
                {
                    summary.title ?
                        <p>{t('text.practitioner_report_result1', {
                            score: num,
                            high_score: summary.total && summary.total.length == 2 ? summary.total[1] : "64",
                            result: summary.title
                        })}</p>
                        :
                        <p>{t('text.practitioner_report_result1', {
                            score: summary.total && summary.total.length == 2 ? summary.total[0] : "20",
                            high_score: summary.total && summary.total.length == 2 ? summary.total[1] : "46",
                            outcome: num
                        })}</p>
                }
                <hr></hr>
                {summary.title ?
                    <>
                        <h6 className="summary-title">{summary.title}</h6>
                        <div className="summary-text" dangerouslySetInnerHTML={{ __html: summary.summary }} ></div>
                    </>
                    : null}
            </div>

        </>
    )

}

export default withTranslation()(ReportSummary);
