import React from 'react';
import { withTranslation } from 'react-i18next';

class Disclaimer extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div
                className="disclaimer-container"
                dangerouslySetInnerHTML={{ __html: t('html.congenital_disclaimer') }}
            />
        )
    }
}

export default withTranslation()(Disclaimer)