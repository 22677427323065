import React from "react";
import { withTranslation } from 'react-i18next';
import { Table, Button, Spinner } from "react-bootstrap";

class BillingTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        let { loading_status, t } = this.props;

        return (
            <div className="cmnTable">
                <Table className="table-billing-responsive" responsive striped>
                    <thead>
                        <tr>
                            <th>{t('name')}</th>
                            <th>{t('email_address')}</th>
                            <th>{t('user_type')}</th>
                            <th>{t('num_of_reports_viewed')}</th>
                            <th>{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading_status && this.props.userDetails.length <= 0 ? <tr style={{ textAlign: "center" }}><td colSpan={10}><h5>{t('no_data_available')}</h5></td></tr> : null}

                        {
                            loading_status ? <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '250%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} /> :
                                this.props.userDetails && this.props.userDetails.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.practitioner.first_name + " " + user.practitioner.last_name}</td>
                                        <td>{user.practitioner.email}</td>
                                        <td>{user.practitioner.type}</td>
                                        <td>{user.count}</td>
                                        <td>
                                            <Button className="show-kits" onClick={() => this.props.showKits(index)}>
                                                {t('click_view_kit_id')}
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(BillingTable);
