import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs"
import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    let [current, setCurrent] = useState({ "selected": props.skip })
    const { t } = props;

    function handleChange(selected) {
        let select = { "selected": selected }
        setCurrent(select)
        props.paginationClick(selected)
    }
    return (
        <>
            {
                props.pages.length > 0 &&
                <div className="pagination-wrp">
                    <ReactPaginate
                        previousLabel={<><span><BsCaretLeftFill />{t('pagination.previous')}</span></>}
                        nextLabel={<><span>{t('pagination.next')}<BsCaretRightFill /></span></>}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={props.pages.length}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        forcePage={props.skip}
                        selected={current}
                        onPageChange={(page) => handleChange(page.selected)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            }

        </>
    );
};

export default withTranslation()(Pagination);
