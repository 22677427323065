import { React } from "react";
import moment from 'moment'
const OverviewRecentDetails = ({ icon, details }) => {
    return (
        <>
            <div className="overviewDetails">
                <h5>{details.msg}</h5>

                <ul className="list-unstyled">
                    {details.last3Date && details.last3Date.length > 0 && details.last3Date.map((date, i) => (
                        <li key={i}><img alt="icon" src={icon} className="filterit"></img> {moment(date).format('dddd')} - <span>{details.last3list[i]}</span></li>

                    ))}
                </ul>

            </div>
        </>
    )
}

export default OverviewRecentDetails