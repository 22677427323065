import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

const BreadCrumb = ({ title, t }) => {
    return (
        <div className="breadcrumb-wrp">
            <Breadcrumb>
                <Breadcrumb.Item href="#">{t('heading.customers')}</Breadcrumb.Item>
                {title !== t('heading.customers') ? <Breadcrumb.Item active> {title}</Breadcrumb.Item> : null}
            </Breadcrumb>
        </div>
    );
};

export default withTranslation()(BreadCrumb);
