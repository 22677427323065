import { React } from "react";
import { Table } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const ReportDetails = ({ data, t }) => {

    return (
        <>
            <div className="reportDetail-wrp">

                <div className="cmnTable">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>{t('rsid_table.rsid')}</th>
                                <th>{t('rsid_table.genotype_index')}</th>
                                <th>{t('rsid_table.notes')}</th>
                                <th>{t('rsid_table.reference')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.rsid}</td>
                                        <td>{item.index}</td>
                                        <td style={{ width: "30%" }}>{item.value.unique ? item.value.unique_value : null}</td>
                                        <td>
                                            <a href={item.value.reference} target="__blank" className="reference-link">
                                                {item.value.reference}
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        {t('rsid_table.no_results')}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default withTranslation()(ReportDetails)
