import React from "react";
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next"

import { alertActions } from "../../actions";
class PDFViewer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showpdf: false,
            loading: false,
            modal: [],
            numPages: null,
            pageNumber: 1,
            scale: 1.17,
            changePage: 1
        }
    }

    async componentDidMount() {
        console.log("component did mount", this.props.showpdf)
        this.setState({ showpdf: this.props.showpdf })
    }


    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages, pageNumber: 1 });
    }

    previousPage() {
        this.setState({ pageNumber: this.state.pageNumber - 1 })
    }

    nextPage() {
        this.setState({ pageNumber: this.state.pageNumber + 1 })
    }

    handlePDFModal = async () => {
        this.setState({ showpdf: !this.state.showpdf, modal: "Hello" })
    }

    scrollToItem(page) {
        this.setState({ pageNumber: parseInt(page) })
    };

    render() {
        const { t } = this.props;
        const { pageNumber, numPages, changePage, scale } = this.state;

        return (
            <>
                <Modal show={this.state.showpdf} onHide={() => this.handlePDFModal()} centered className="trackingModalPdf" size="sm" >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('title.pdf_guide')}:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="training-pdf-modal">
                        <Document
                            file="/pdf/Guide.pdf"
                            onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
                            options={{
                                workerSrc:
                                    "cdn.jsdelivr.net/npm/react-pdf@4.0.0-beta.5/dist/pdf.worker.entry.js"
                            }}
                        >
                            <Page pageNumber={pageNumber} scale={scale} wrap={true} />
                        </Document>
                        <div className="pdf-controls">
                            <button type="button" onClick={() => this.setState({ scale: scale - 0.1 })}>
                                -
                            </button>
                            {t('buttons.zoom')}
                            <button type="button" onClick={() => this.setState({ scale: scale + 0.1 })}>
                                +
                            </button>
                            <button type="button" disabled={pageNumber <= 1} onClick={() => this.previousPage()}>
                                {t('buttons.previous')}
                            </button>
                            <p>
                                {t('page')} {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                            </p>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={() => this.nextPage()}
                            >
                                {t('buttons.next')}
                            </button>
                            <input value={changePage} size={5} type="number" style={{ width: "72px" }} onChange={e => this.setState({ changePage: e.target.value })} />
                            <button type="button" onClick={() => this.scrollToItem(changePage)}>

                                {t('buttons.goto')}
                            </button>
                            <button type="button">
                                <Link to="/pdf/Guide.pdf" target="_blank" download>{t('buttons.download')}</Link>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
};


function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    success: alertActions.success
}

export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(PDFViewer);