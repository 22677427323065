import React from 'react';
import { withTranslation } from 'react-i18next';

class Intro extends React.Component {
    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        const { t } = this.props;
        return (
            <div className="intro-text" dangerouslySetInnerHTML={{ __html: t('html.portal_intro') }} />
        )
    }
}

export default withTranslation()(Intro);