import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Loader from "../../../components/Loader/Loader";

import { userActions, alertActions } from '../../../actions';
import { apiService } from "../../../services/api_service";
import { otpErrorMessages } from "../../../constants";
import { getPartnerUrlPrefix } from "../../../helpers/partner";

class Otp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      email: props.history.location.state?.email,
      submitted: false,
      loading: false,
      otpError: false,
      disabled: false,
      token: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  timeoutId = null;

  componentWillMount() {
    this.setState({ loading: true })
  }

  async componentDidMount() {
    let partner_id = this.props.partner
    // set default color
    if (partner_id == '7') {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#000000');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#000000');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#a6a6a6');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#e6e6e6');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#818181');
      document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
    } if (partner_id == '45') {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#080B37');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#080B37');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#636ce9');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#d2d4f9');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#3642e2');
      document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
    }
    else if (partner_id === 46) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#2c2c2c");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#2c2c2c");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#b3b3b3");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#f2f2f2");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#e6e6e6");
      document
        .getElementById("bodyID")
        .style.setProperty("--navtext", "#ffffff");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "22px 0px");
    }
    else if (partner_id === 48) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#65C0B6");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#65C0B6");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#c9e9e5");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#edf8f6");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#b7e1dc");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "9px 0px");
    }
    else if (partner_id === 11) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#76D3CA");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#76D3CA");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#c5ece8");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#ecf9f7");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#d8f3f0");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "22px 0px");
    }
    else if (partner_id === 15) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#243144");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#243144");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#bcc9dc");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#eef1f6");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#cdd6e5");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "12px 0px");
    }
    else if (partner_id === 50) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#05aaff");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#05aaff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#b3e6ff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#cceeff");
      document
        .getElementById("bodyID")
        .style.setProperty("--navtext", "#ffffff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#cdd6e5");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "15px 0px");
    }
    else if (partner_id === 51) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#b34944");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#b34944");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#f8eded");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#f8eded");
      document
        .getElementById("bodyID")
        .style.setProperty("--navtext", "#ffffff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#FCFCFA");

    }
    else if (partner_id === 52) {
      document.getElementById("bodyID").style.setProperty("--themecolor", "#6037d9");
      document.getElementById("bodyID").style.setProperty("--themefontcolor", "#6037d9");
      document.getElementById("bodyID").style.setProperty("--liteThemecolor", "#b7a5ee");
      document.getElementById("bodyID").style.setProperty("--liteBackgroundColor", "#d7cdf6");
      document.getElementById("bodyID").style.setProperty("--liteButtonBackground", "#aa81ff");
      document.getElementById("bodyID").style.setProperty("--navtext", "#ffffff");
      document.getElementById("bodyID").style.setProperty("--headpadding", "22px 0px");
    }
    else {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#09a79e');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#09a79e');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#09a79e69');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#09a79e17');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#0ac2b9');
      document.getElementById("bodyID").style.setProperty('--filterSvg', '');
    }


    let token = sessionStorage.getItem('accessToken');

    if (!token) {
      this.setState({ disabled: true });
      this.props.clearAlerts();
      this.props.history.push("/signin");
    } else {
      let validate_token = await apiService.TokenValidate({
        type: "TOKEN",
        authorizationToken: `Bearer ${token}`,
      });

      if (!validate_token) {
        this.props.error("The Link has been expired");
        this.setState({ disabled: true });
        setTimeout(() => {
          this.props.clearAlerts();
          this.props.history.push("/signin");
        }, 1000);
      }
      this.setState({ loading: false, token });
    }
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      if (this.state.otp) {
        e.preventDefault()
        this.handleSubmit()
      } else {
        this.setState({ otpError: true })
      }
    }
  }

  handleChange(e) {
    this.setState({ otp: e.target.value, otpError: false });
  }

  async handleSubmit() {
    const { otp, token } = this.state;

    if (this.state.disabled || !token || otp.trim().length !== 6) return;

    this.setState({ submitted: true });
    let response = await this.props.otp(this.props.history, otp, token);

    if (response.statusCode === 400) {
      this.setState({ submitted: false });

      if (response.message === otpErrorMessages.wrong_otp_attempt_exceed) {
        this.setState({ disabled: true });

        setTimeout(() => {
          this.props.history.push("/signin");
        }, 2000);
      } else if (
        [
          otpErrorMessages.link_expired1,
          otpErrorMessages.link_expired2,
        ].includes(response.message)
      ) {
        this.setState({ disabled: true });
      }
      return;
    }

    sessionStorage.removeItem('accessToken');

    this.timeoutId = setTimeout(() => {
      this.setState({ submitted: false, otpError: false });
    }, 500);
  }

  componentDidUpdate() {
    if (this.props.alert.message) {
      setTimeout(() => {
        if (!this.state.disabled) { this.props.clearAlerts(); }
      }, 10000)
    }
  }

  componentWillUnmount() {
    // Clear the timeout if the component is unmounted
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleClose = () => {
    this.props.clearAlerts();
  }

  render() {
    const { loggingIn, alert, partner, t } = this.props;
    const { otp, loading, submitted, otpError } = this.state;
    return (
      <div className="formPart signinForm">
        {alert.message &&
          <div className={`alert ${alert.type}`} style={{ marginBottom: 0 }}>
            {alert.message}
            <button className="close" onClick={this.handleClose} >x</button>
          </div>
        }
        <Loader loading={loading} />
        <h1>{t('heading.one_time_passcode')}</h1>
        <h6>{t('enter_otp')}:</h6>
        <Form>
          <Form.Group className="sign-field">
            <Form.Control placeholder={t('otp')} type="text" name="OTP" value={otp} maxLength={6} onChange={this.handleChange} onKeyPress={this.handleKeyPress} required />
            <Form.Text className="text-muted">
              {otpError ? t('enter_otp') : null}
              {(this.props.alert.message && this.props.alert.message.includes("OTP")) ? t('validations.otp_not_match') : null}
            </Form.Text>
          </Form.Group>

          <Button
            disabled={this.state.disabled || this.state.otp.length !== 6 || !this.state.token}
            className="continuebtn" onClick={this.handleSubmit}>
            {t('buttons.login')} {submitted && <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style={{ float: 'center' }} />}
          </Button>
          {<a className="backtologin" href={`${getPartnerUrlPrefix(partner)}/signin`}>{t('back_to_login')}</a>}
        </Form>
      </div>
    );
  }
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  otp: userActions.otp,
  login: userActions.login,
  error: alertActions.error,
}
export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(Otp);