import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './styles.css'

class Error404External extends Component {
    constructor() {
        super()
        this.state = {}
    }
    componentDidMount() {
    }
    render() {
        const { t } = this.props;
        return (
            <div className="error404">

                <section class="page_404">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 ">
                                <div class="col-sm-10 col-sm-offset-1  text-center" style={{ paddingTop: "200px" }}>
                                    {/* <div class="four_zero_four_bg" style={{width: "800px"}}>
                                    <h1 class="text-center ">404</h1>
                                </div> */}

                                    <div class="contant_box_404" style={{ paddingLeft: "170px" }}>
                                        <h3 class="h2">
                                            {t('title.404_lost')}
                                        </h3>

                                        <p>{t('page_not_found')}</p>

                                        <a href="/" class="link_404">{t('goto_home')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslation()(Error404External);