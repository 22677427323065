import { React } from "react";
import { withTranslation } from 'react-i18next';

const UserRegisteredcountCard = ({ t, ...props }) => {

  return (
    <>
      <div className="userRegisteredcard">
        <h2>{t('registered_users')}</h2>
        <ul className="list-unstyled">
          <li>
            <img src="/images/biuser.svg" className="filterit"></img> <span>{props.userDetail.total} </span>
            {t('registered_users')}
          </li>
          <li>
            <img src="/images/Union.svg" className="filterit"></img> {t('last_week')} -{" "}
            <span>{props.userDetail.lastWeek} {t('new_users')}</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default withTranslation()(UserRegisteredcountCard);
