import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap'
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "bootstrap-icons/font/bootstrap-icons.css";
// import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';  // Import the i18n instance

ReactDOM.render(
    <React.StrictMode>
        <Suspense
            fallback={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Spinner
                        className="spinnerClass"
                        animation="border"
                        variant="light"
                        size="sm"
                    />
                </div>}>
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();