import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, Button, Spinner } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import moment from 'moment'

import { apiService } from "../../services/api_service"

import DoughnutCard from "../../components/Graphs/DoughnutCard/DoughnutCard";
import LineGraphs from "../../components/Graphs/LineGraphs";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: false,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        font: {
          size: 14,
        },
      },
      title: {
        color: "1E1E1E",
        display: true,
        text: "Average Minutes per Month",
      },
    },
    x: {
      title: {
        color: "1E1E1E",
        display: true,
        text: "Average Month",
      },
    },
  },
};
class Engagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "123",
      devices: [],
      active: [],
      lengthGraphData: [],
      lengthGraphLabels: [],
      sessionData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "#8AD4F1",
            maxBarThickness: 40,
          },
        ],
      },
      time: [],
      loader: false,
      /* Date Picker */
      startDate: moment().subtract(1, "year").toDate(),
      endDate: moment().toDate(),
    }
  }

  async componentDidMount() {
    if (this.props.user?._id) {
      await this.getEngagementData();
    }
  }

  shouldComponentUpdate() {
    return true
  }

  async componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        this.setState({
          practitioner_id: btoa(nextProps.user._id),
          ...nextProps.user,
        });
        await this.getEngagementData();
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  monthDiff(dateFrom, dateTo) {
    return (dateTo.getMonth() - dateFrom.getMonth()) +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }

  generateEngagementGraphData(data) {
    const { startDate, endDate } = this.state;
    const months = this.monthDiff(startDate, new Date());
    const monthsFromNow = this.monthDiff(endDate, new Date());

    let graphLabels = [], graphData = [];
    let month = [];
    for (let i = months; i >= monthsFromNow; i--) {
      month.push(moment().subtract(i, "months"));
    }
    month.map((element) => {
      let flag = true;
      data.map((x) => {
        if ((moment(element).month() + 1) == x.date.month && moment(element).year() == x.date.year) {
          graphData.push(parseInt(x.count));
          flag = false;
        }
      });
      if (flag) {
        graphData.push(0);
      }
      graphLabels.push(moment(element).format("MMM YYYY"));
    });

    this.setState({ engagementGraphData: graphData, engagementGraphLabels: graphLabels });
  }
  generateLengthOfTimeGraphData(data) {
    const { startDate, endDate } = this.state;
    const months = this.monthDiff(startDate, new Date());
    const monthsFromNow = this.monthDiff(endDate, new Date());

    let graphLabels = [], graphData = [];
    let month = [];
    for (let i = months; i >= monthsFromNow; i--) {
      month.push(moment().subtract(i, "months"));
    }
    month.map((element) => {
      let flag = true;
      data.map((x) => {
        if ((moment(element).month() + 1) == x.date.month && moment(element).year() == x.date.year) {
          graphData.push(parseInt(x.minutes));
          flag = false;
        }
      });
      if (flag) {
        graphData.push(0);
      }
      graphLabels.push(moment(element).format("MMM YYYY"));
    });

    let sessionData = {
      labels: graphLabels,
      datasets: [
        {
          data: graphData,
          backgroundColor: "#8AD4F1",
          maxBarThickness: 40,
        },
      ],
    };

    this.setState({ sessionData });
  }
  async getEngagementData() {
    if (!this.props.user?._id) return

    try {
      this.setState({ loader: true });
      const { startDate, endDate } = this.state;

      const practitioner_id = btoa(this.props.user._id);

      // Doughnut graph
      let platformuser = await apiService.BIEngagementPlatformUsers(
        practitioner_id,
        startDate,
        endDate
      );

      const totalPlatformUsers = platformuser.reduce(
        (total, i) => total + parseInt(i.count),
        0
      );

      platformuser.map((items) => {
        items.percent = Math.floor((items.count / totalPlatformUsers) * 100);
        if (typeof items.title !== "string") {
          items.title = "OTHERS";
        }
      });
      this.setState({ devices: platformuser });

      // Engagement graph
      let activeuser = await apiService.BIEngagementActive(
        practitioner_id,
        startDate,
        endDate
      );
      this.generateEngagementGraphData(activeuser);

      // Length of time graph
      let session = await apiService.BIEngagementSession(
        practitioner_id,
        startDate,
        endDate
      );
      this.generateLengthOfTimeGraphData(session);

      this.setState({ loader: false });
    } catch (error) {
      console.log("error", error);
    }

  }

  handleChangeStart(date) {
    const { endDate } = this.state;

    if (date > endDate) {
      alert(this.props.t('validations.end_date'));
      return;
    }
    this.setState({ startDate: date });
  }

  handleChangeEnd(date) {
    const { startDate } = this.state;

    if (date < startDate) {
      alert(this.props.t('validations.end_date'));
      return;
    }
    this.setState({ endDate: date });
  }

  async handleSubmit() {
    const { startDate, endDate } = this.state;
    /* Validation Start */
    if (startDate > endDate) {
      alert(this.props.t('validations.end_date'));
      return;
    }

    await this.getEngagementData();
  }

  render() {
    const { t } = this.props;
    const { startDate, endDate, sessionData } = this.state;
    return (
      <>
        {this.state.loader ?
          <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />
          :
          <div className="businessinfo-engagement-wrp">
            <div style={{ 'paddingLeft': '30px' }} className="row">
              {/* Date Picker Start */}
              <Form.Group controlId="formStartDate" style={{ marginRight: "10px" }}>
                <Form.Label>{t('labels.start_date')}</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(e) => this.handleChangeStart(e)}
                  onClickOutside={this.openDatePicker}
                  maxDate={new Date()}
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                ></DatePicker>
              </Form.Group>

              <Form.Group controlId="formEndDate" style={{ marginRight: "10px" }}>
                <Form.Label>{t('labels.end_date')}</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={(e) => this.handleChangeEnd(e)}
                  onClickOutside={this.openDatePicker}
                  maxDate={new Date()}
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                ></DatePicker>
              </Form.Group>
              {/* Date Picker End */}
              <Form.Group controlId="formSubmitBtn" style={{ marginTop: "30px", marginRight: "10px" }}>
                <Button onClick={() => this.handleSubmit()} className="dropdownBtn">{t('buttons.submit')}</Button>
              </Form.Group>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-3">
                <DoughnutCard title={t('title.device_type')} classname="stacked" devices={this.state.devices}></DoughnutCard>
              </div>

              <div className="col-12 col-lg-6 col-xl-5">
                <div className="graph-wrp" style={{ height: '536px' }}>
                  <h5>{t('title.engagement_per_month')}</h5>
                  <LineGraphs
                    labels={this.state.engagementGraphLabels}
                    data={this.state.engagementGraphData}
                    xLabel={t('graph.month')}
                    yLabel={t('graph.users')}
                  >
                  </LineGraphs>
                </div>
              </div>

              <div className="col-12 col-lg-12 col-xl-4">
                {/* use this for Bar chart */}
                {/* <BarGrapgh></BarGrapgh> */}
                {/* use this for Bar chart */}
                <div className="graph-wrp" style={{ height: '536px' }}>
                  <h5>{t('title.length_of_time')}</h5>
                  <div className="barchart-wrp cmnchart-wrp">
                    <Bar data={sessionData} options={options} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        }
      </>
    );
  }

};


function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  //success: alertActions.success
}

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(Engagement);