import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { EyeIconHide, EyeIconShow } from "../Icons/EyeIcons";

class SetPasswordForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      newpassword: "",
      confirmpassword: "",
      hide: true,
      emailError: false,
      passwordError: false,
      passwordFormat: false,
      hide1: true,
      confirmError: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleUnhidePassword = this.handleUnhidePassword.bind(this);
    this.handleUnhideConfirmPass = this.handleUnhideConfirmPass.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePassword(e) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    if (this.state.email && this.state.email.match(regexEmail)) {
      e.preventDefault()
      const { name, value } = e.target;
      this.setState({ newpassword: value, emailError: false, passwordError: false, confirmError: false });
    } else {
      this.setState({ emailError: true })
    }

  }

  handlePasswordChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      // Check password is empty
      if (this.state.newpassword == "") {
        this.setState({ passwordError: true });
        return;
      } else {
        this.setState({ passwordError: false });
      }
      // Password format check
      let passwordregx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/
      if (this.state.newpassword.match(passwordregx)) {
        this.setState({ passwordFormat: false })
      } else {
        this.setState({ passwordFormat: true })
        // return;
      }
      if (this.state.confirmpassword && this.state.newpassword != this.state.confirmpassword) {
        this.setState({ confirmError: true })
      } else {
        this.setState({ confirmError: false })
      }
    });

  }

  handleConfirmPasswordChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      // Confirm password match check
      if (this.state.newpassword !== this.state.confirmpassword) {
        this.setState({ confirmError: true });
        // return;
      } else {
        this.setState({ confirmError: false });
      }
    });
  }

  handleKeyPress(e) {
    // let passwordregx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/
    // if(this.state.newpassword && this.state.newpassword.match(passwordregx)){
    //   this.setState({passwordFormat: false})
    // }else{
    //   if(this.state.newpassword !== ""){
    //     this.setState({passwordFormat: true})
    //   }else{
    //     this.setState({passwordFormat: false})
    //   }
    // }
    if (e.charCode === 13) {
      if (this.state.newpassword && this.state.confirmpassword && !this.state.passwordFormat) {
        e.preventDefault()
        this.handleSubmit()
      }
      // else{
      //   if(!this.state.newpassword ){
      //       this.setState({passwordError: true })
      //   }else{
      //     if(!this.state.confirmpassword || this.state.newpassword !== this.state.confirmpassword){
      //       this.setState({passwordError: false, confirmError: true })
      //     }else {
      //       this.setState({passwordError: false, confirmError: false })
      //     }
      //   }
      // }
    }
  }

  handleUnhidePassword() {
    this.setState({ hide: !this.state.hide })
  }
  handleUnhideConfirmPass() {
    this.setState({ hide1: !this.state.hide1 })
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  async handleSubmit(e) {
    let passwordError = false, confirmError = false
    if (this.state.newpassword == "") {
      passwordError = true;
    }
    if (this.state.newpassword !== this.state.confirmpassword) {
      confirmError = true;
    }

    this.setState({ passwordError, confirmError });

    if (passwordError || confirmError || this.state.passwordFormat) {
      return;
    }
    const { email, newpassword, confirmpassword } = this.state;
    await this.props.handleSubmit({ email, newpassword, confirmpassword });
  }

  componentDidMount() {
    this.setState({ email: this.props.email });
  }

  render() {
    const { t } = this.props
    const { newpassword, confirmpassword, hide, email,
      emailError, passwordError, confirmError, passwordFormat, hide1 } = this.state;
    return (
      <>
        <h1>{t('heading.set_password')}</h1>
        <h6>{t('set_password_app')}</h6>
        <Form>
          <Form.Group className="sign-field">
            <Form.Control placeholder={t('placeholder.email')} type="text" name="email" value={email} disabled required />
            <Form.Text className="text-muted">
              {emailError ? t('validations.email') : null}
            </Form.Text>
          </Form.Group>
          <Form.Group className="sign-field">
            <div className="passwordfeild">
              <Form.Control placeholder={t('placeholder.new_password')} type={this.state.hide ? "password" : "text"} name="newpassword" value={newpassword} onChange={this.handlePasswordChange} onKeyPress={this.handleKeyPress} required />
              <span className="eyeicon" onClick={this.handleUnhidePassword}>
                {this.state.hide ? <EyeIconHide></EyeIconHide> : <EyeIconShow></EyeIconShow>}
              </span>
            </div>
            <Form.Text className="text-muted">
              {passwordError ? t('validations.password') : null}
              {passwordFormat ? t('password_format_validation_error') : null}
            </Form.Text>
          </Form.Group>
          <Form.Group className="sign-field">
            <div className="passwordfeild">
              <Form.Control placeholder={t('placeholder.confirm_password')} type={this.state.hide1 ? "password" : "text"} name="confirmpassword" value={confirmpassword} onChange={this.handleConfirmPasswordChange} onKeyPress={this.handleKeyPress} required />
              <span className="eyeicon" onClick={this.handleUnhideConfirmPass}>
                {this.state.hide1 ? <EyeIconHide></EyeIconHide> : <EyeIconShow></EyeIconShow>}
              </span>
            </div>
            <Form.Text className="text-muted">
              {confirmError ? t('validations.confirm_password') : null}
            </Form.Text>
          </Form.Group>
          <Button className="submitbtn" onClick={this.handleSubmit}
            disabled={
              !this.props.email || !this.props.token ||
              this.props.submitted
            }
          >
            {t('buttons.set_password')}
          </Button>
        </Form>
      </>
    );
  }
};


export default withTranslation()(SetPasswordForm);
